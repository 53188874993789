import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { ProfileType } from '../models/profile.model';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { AuthUser } from '../models/auth-user.model';
import { isDataSource } from '@angular/cdk/collections';
export const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getUserByEmail(email: string): Observable<AuthUser> {
    return this.get<AuthUser>(`/user-information/information/email/${email}`);
    //return this.get<SystemUser>(`ge-api/auth/user/email/?email=${email.replace('+', '%2B')}`);
  }

  getUserById(id: string): Observable<AuthUser> {
    return this.get<AuthUser>(`/user-information/information/id/${id}`)
  }

  /*getUserById(idInt: number): Observable<SystemUser> {
    return this.post<SystemUser[]>(`ge-api/auth/user/ids`, [idInt])
      .pipe(map(result => {
        return result[0];
      }))
    }*/

  getProfile() : Observable<ProfileType> {
      return this.get<ProfileType>(GRAPH_ENDPOINT);
  }

  getUserInfo(userIds: string[]): Observable<AuthUser[]> {
    if (userIds == null || userIds.length == 0) {
      return of([]);
    }

    //Keep this in case old ID's cause issues, this just removes null values
    let filteredValues = userIds.filter(function(e) {
      return e != null && e != '';
    });
    let uniqueValues = filteredValues.filter((n, i) => filteredValues.indexOf(n) === i);
    return this.post<AuthUser[]>('/user-information/information/emails', {ids: uniqueValues});
}

getUserInfoByIds(userIds: string[]): Observable<AuthUser[]> {
  if (userIds == null || userIds.length == 0) {
    return of([]);
  }

  //Keep this in case old ID's cause issues, this just removes null values
  let filteredValues = userIds.filter(function(e) {
    return e != null && e != '';
  });
  let uniqueValues = filteredValues.filter((n, i) => filteredValues.indexOf(n) === i);
  return this.post<AuthUser[]>('/user-information/information/ids', {ids: uniqueValues});
}

  /*getUserInfo(userIds: number[]): Observable<SystemUser[]> {
      if (userIds == null || userIds.length == 0) {
        return of([]);
      }

      let uniqueValues = userIds.filter((n, i) => userIds.indexOf(n) === i);
      return this.post<SystemUser[]>('ge-api/auth/user/ids', uniqueValues);
  }*/

  getUsersWithHarvests(): Observable<AuthUser[]> {
    return this.getMany<string>('harvest-history-api/documentreview/user')
      .pipe(mergeMap(users => this.getUserInfo(users)));
  }

  createUser(email: string, firstName: string, lastName: string): Observable<AuthUser> {
    return this.post<AuthUser>(`harvest-history-api/user`, { emailAddress: email, firstName: firstName, lastName: lastName });
  }
}
