import { ComponentType } from '@angular/cdk/portal/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogOptions } from './confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openDialog(component: any, config?: MatDialogConfig<any>): Observable<any> {
    return this.dialog.open(component, config).afterClosed();
  }

  openConfirmationDialog(config: MatDialogConfig<ConfirmationDialogOptions>): Observable<boolean> {
    return this.dialog.open(ConfirmationDialogComponent, config).afterClosed();
  }

  openUnsavedChangesDialog(options?: ConfirmationDialogOptions): Observable<boolean> {
    return this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'unsaved-changes-dialog',
      disableClose: true,
      data: options ?? new ConfirmationDialogOptions('Unsaved Changes', 'Your changes may be lost. Please save your changes before leaving the page.', 'Cancel', 'Leave')
    }).afterClosed();
  }
}
