import { Component, Input, OnInit } from '@angular/core';
import { AuthUser } from 'src/app/models/auth-user.model';

@Component({
  selector: 'hh-user-document-reviews',
  templateUrl: './user-document-reviews.component.html',
  styleUrls: ['./user-document-reviews.component.scss']
})
export class UserDocumentReviewsComponent implements OnInit {
  @Input()
  set user(user: AuthUser | undefined) {

  }

  constructor() { }

  ngOnInit(): void {
  }

}
