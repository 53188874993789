import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { finalize } from "rxjs/operators";
import { Review } from 'src/app/models/harvest-review.model';
import { DocumentService } from 'src/app/services/document.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { MessageType } from 'src/app/messages/messages.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { ConfirmationDialogOptions } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { createMessageContent, validEmail } from 'src/app/utility';
import { RmaService } from 'src/app/services/rma.service';
import { ServiceType } from 'src/app/models/type.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'hh-harvest-list',
  templateUrl: './harvest-list.component.html',
  styleUrls: ['./harvest-list.component.scss']
})
export class HarvestListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  currentYear = new Date().getFullYear();
  dataSource = new MatTableDataSource<Review>();
  data: Review[] = [];
  search: string = '';
  loading = false;
  loadingReviewStatuses = false;
  showSuccess: boolean = false;
  successText = '';
  successMessageType: MessageType = 'success';
  errorText = '';
  errorTitle = '';
  reviewerId ='';
  messageType: MessageType = 'alert';
  reviewStatusTypes!: ServiceType[];
  reviewStatusTypesControl = new FormControl();
  reviewStatusSelectedTypes: string[] = [];
  selectedRow!: Review | null;
  localStorageFilterKey: string = 'ReviewStatusFilter';
  localStorageSelectedRow: string = 'ReviewListSelectedRow';

  constructor(
    private harvestService: HarvestService,
    private documentService: DocumentService,
    private router: Router,
    private route: ActivatedRoute,
    private rmaService: RmaService,
    private dialog: DialogService
    //private changeDetector : ChangeDetectorRef
  ) { }

  /*ngAfterViewInit(): void {
    this.loadInitialHarvests();
  }*/

  ngOnInit(): void {
    this.subscribeToParams();
    this.loadInitialHarvests();
  }

  subscribeToParams(): void {
    this.route.params.subscribe(params => {
      //So this one has the same logic as the documents list, except we don't actually need the email or even the user, this is really weird but gonna leave it in case we eventually do need it
      if (params['closed'] && params['closed'] !== '') {
        const email = params['closed'];

        if (validEmail(email)) {
          this.successText = 'Successfully Closed';
          this.showSuccess = true;
        };
      }

      if (params['abandoned'] && params['abandoned'] !== '') {
        const email = params['abandoned'];

        if (validEmail(email)) {
          this.successText = 'Successfully Abandoned';
          this.showSuccess = true;
        };
      }
    })
  }

  applyFilter = () => {
    this.dataSource.filter = this.search?.trim().toLocaleLowerCase() ?? '';
  }

  loadInitialHarvests(): void {
    this.harvestService.getReviewStatuses()
      .subscribe(reviewStatuses => {
      this.loadingReviewStatuses = false;
      this.reviewStatusTypes = reviewStatuses;
      let initialFilters = this.getLocalStorageValue(this.localStorageFilterKey);

      if (initialFilters && initialFilters.length > 0) {
        this.reviewStatusTypesControl.setValue(initialFilters);
        this.getHarvestsByStatuses(initialFilters, false, this.getLocalStorageValue(this.localStorageSelectedRow));
      }
      else {
        const open = this.reviewStatusTypes.find(x => x.type.toLowerCase() === 'open') || null;
        if (open !== null) {
          this.reviewStatusTypesControl.setValue([open.id]);
          this.getHarvestsByStatuses([open.id], false, this.getLocalStorageValue(this.localStorageSelectedRow));
        }
      }
    },
    error => {
      this.loadingReviewStatuses = false;
      if (error.status) {
        const message = createMessageContent(error.status);
        this.errorTitle = message?.title ?? '';
        this.errorText = message?.messages[0] ?? '';
      }
      else {
        this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
      }
    })
  }

  getHarvestsByStatuses(statusIds: string[], applyFilter: boolean = false, selectedRowId: string = '') {
    this.loading = true;
    this.harvestService.getReviewsByStatus(statusIds)
    .pipe(finalize(() => this.loading = false))
    .subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: Review, property: string) => {
        switch(property) {
          case 'reviewerName':
            return item.reviewerName.toLowerCase();
          case 'email':
            return item.email.toLowerCase();
          case 'state':
            return item.state.toLowerCase();
          case 'commodity':
            return item.commodityName.toLowerCase();
          default:
            return (item as any)[property];
        }
      }
      this.data = result;

      if(this.dataSource && this.dataSource.sort) {
        this.dataSource.sort.active = 'reviewerName';
        this.dataSource.sort.direction = 'desc';
        this.dataSource.sort.sortChange.emit({ active: this.dataSource.sort.active, direction: this.sort.direction });
        if (applyFilter) { this.filterReviews(); }

        if (this.dataSource.data && this.dataSource.data.length > 0 && selectedRowId && selectedRowId !== '') {
          let resultRow = this.dataSource.data.find(x => x.harvest.id?.toLowerCase() === selectedRowId.toLowerCase());
          if (resultRow) {
            this.selectedRow = resultRow;
          }
        }
      }
    }, error => {
      if (error.status) {
        const message = createMessageContent(error.status);
        this.errorTitle = message?.title ?? '';
        this.errorText = message?.messages[0] ?? '';
      } else {
        this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
      }
    });
    // this.loading = true;
    // forkJoin({
    //   cropTypes: this.rmaService.getCropTypes({reinsuranceYearEqualsFilter: this.currentYear.toString()}),
    //   states: this.rmaService.getStates({ reinsuranceYearEqualsFilter: this.currentYear.toString()}),
    //   reviews: this.harvestService.getAllHarvestsForReview()
    // })
    // .pipe(finalize(() => this.loading = false))
    // .subscribe(result => {
    //   if (result.cropTypes.length === 0) {
    //     this.errorTitle = 'Error';
    //     this.errorText = 'The server is currently unavailable. Try refreshing this page, or navigate to the home page.';
    //     return;
    //   }

    //   let reviews: Review[] = [];
    //   if(result.reviews && result.reviews.length > 0) {
    //     reviews = result.reviews.map(r => {
    //       r.commodityId = result.cropTypes.find(ct => ct.id == r.commodityId)?.id ?? r.commodityId
    //       r.commodityName = result.cropTypes.find(ct => ct.id == r.commodityId)?.name ?? "N/A"
    //       r.stateName = result.states.find(m => m.id == r.state)?.name ?? "N/A"
    //       return r;
    //     });
    //   }

    //   this.dataSource.data = reviews;
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.sortingDataAccessor = (item: Review, property: string) => {
    //     switch (property) {
    //       case 'userName':
    //         return item.lastName + ', ' + item.firstName;
    //       default: return (item as any)[property];
    //     }
    //   }
    // },
    //   error => {
    //     if (error.status) {
    //       const message = createMessageContent(error.status);
    //       this.errorTitle = message?.title ?? '';
    //       this.errorText = message?.messages[0] ?? '';
    //     }
    //     else {
    //       this.errorTitle = 'Error';
    //       this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
    //     }
    //   }
    // );
  }

  getHarvests() {
    this.loading = true;
    forkJoin({
      cropTypes: this.rmaService.getCropTypes({reinsuranceYearEqualsFilter: this.currentYear.toString()}),
      states: this.rmaService.getStates({ reinsuranceYearEqualsFilter: this.currentYear.toString()}),
      reviews: this.harvestService.getAllHarvestsForReview()
    })
    .pipe(finalize(() => this.loading = false))
    .subscribe(result => {
      if (result.cropTypes.length === 0) {
        this.errorTitle = 'Error';
        this.errorText = 'The server is currently unavailable. Try refreshing this page, or navigate to the home page.';
        return;
      }

      let reviews: Review[] = [];
      if(result.reviews && result.reviews.length > 0) {
        reviews = result.reviews.map(r => {
          r.commodityId = result.cropTypes.find(ct => ct.id == r.commodityId)?.id ?? r.commodityId
          r.commodityName = result.cropTypes.find(ct => ct.id == r.commodityId)?.name ?? "N/A"
          r.stateName = result.states.find(m => m.id == r.state)?.name ?? "N/A"
          return r;
        });
      }

      this.dataSource.data = reviews;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: Review, property: string) => {
        switch (property) {
          case 'userName':
            return item.lastName + ', ' + item.firstName;
          default: return (item as any)[property];
        }
      }
    },
      error => {
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorText = message?.messages[0] ?? '';
        }
        else {
          this.errorTitle = 'Error';
          this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
        }
      }
    );
  }

  openDocumentFile(documentId: string) {
    this.documentService.getDocumentFile(documentId)
      .subscribe(result => {
        if (!result) {
          this.errorTitle = 'Error';
          this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
        }
        window.open(result, '_blank')
      },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.errorTitle = message?.title ?? '';
            this.errorText = message?.messages[0] ?? '';
          }
          else {
            this.errorTitle = 'Error';
            this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
          }
        });
  }

  filterReviews() {
    this.dataSource.filter = this.search;
  }

  updateReview(reviewId: string, emailAddress: string) {
    this.clearMessages();
    this.harvestService.updateReviewToLocked(reviewId)
      .subscribe(
        _ => {
          this.router.navigate(['users/', emailAddress, { reviewId: reviewId}])
        },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.errorTitle = message?.title ?? '';
            this.errorText = message?.messages[0] ?? '';
          }
          else {
            this.errorTitle = 'Error';
            this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
          }
        }
      );
  }

  continueEdit(harvestId: string, harvestOwnerId: string, emailAddress: string) {
    this.clearMessages();
    this.router.navigate(['users/',emailAddress, { harvestId: harvestId, harvestOwnerId: harvestOwnerId}])
  }

  continueReview(reviewId: string, harvestId: string, harvestOwnerId: string, emailAddress: string) {
    this.clearMessages();
    this.reviewerId = reviewId;
    this.router.navigate(['users/', emailAddress, { reviewId: reviewId, harvestId: harvestId, harvestOwnerId: harvestOwnerId, manageBoundaries: true }])
  }

  completeReview(review: Review) {
    const userName = this.formatSystemUserName(review);
    this.clearMessages();
    this.dialog.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Complete Intake', 'Please confirm the "Harvest Intake" is complete for ' + userName + '?', 'Cancel', 'Complete')
    }).subscribe(shouldComplete => {
      if (shouldComplete) {
        this.harvestService.updateReviewToComplete(review.id)
          .subscribe(
            result => {
              this.dataSource.data = this.dataSource.data.filter(d => d != review);
              this.successText = 'Sucessfully completed harvest intake for ' + userName + '.'
              this.showSuccess = true;
            },
            error => {
              if (error.status) {
                const message = createMessageContent(error.status);
                this.errorTitle = message?.title ?? '';
                this.errorText = message?.messages[0] ?? '';
              }
              else {
                this.errorTitle = 'Error';
                this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
              }
            }
          );
      }
    });
  }

  abandonReview(review: Review) {
    const userName = this.formatSystemUserName(review);
    this.clearMessages();
    this.dialog.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Abandon Intake', 'Please confirm you wish to abandon the review for this harvest', 'Cancel', 'Abandon')
    }).subscribe(shouldAbandon => {
      if (shouldAbandon) {
        this.harvestService.updateReviewToAbandoned(review.id)
          .subscribe(
            result => {
              this.dataSource.data = this.dataSource.data.filter(d => d != review);
              this.successText = 'Sucessfully abandoned harvest.'
              this.showSuccess = true;
            },
            error => {
              if (error.status) {
                const message = createMessageContent(error.status);
                this.errorTitle = message?.title ?? '';
                this.errorText = message?.messages[0] ?? '';
              }
              else {
                this.errorTitle = 'Error';
                this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
              }
            }
          );
      }
    });
  }

  formatSystemUserName(review: Review): string {
    return review.lastName + ', ' + review.firstName;
  }

  setReviewStatusFilters(filters: MatSelectChange) {
    this.loading = true;
    this.dataSource.data = [];
    this.reviewStatusSelectedTypes = filters.value;
    this.getHarvestsByStatuses(this.reviewStatusSelectedTypes, this.checkSearchValue());
    this.setLocalStorageValue(this.localStorageFilterKey, filters.value);
  }

  checkSearchValue() {
    return (this.search !== null && this.search !== undefined && this.search !== '') ? true : false;
  }

  getLocalStorageValue(key: string) {
    let storageValue = localStorage.getItem(key)

    if(storageValue && storageValue !== '') {
      return JSON.parse(storageValue);
    }
    else {
      return null;
    }
  }

  setLocalStorageValue(key: string, value: string) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  clearMessages() {
    this.errorTitle = '';
    this.errorText = '';
    this.showSuccess = false;
    this.successText = '';
  }
}
