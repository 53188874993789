<header>
  <h2 class="no-margin">Document Tag Management</h2>
  <div class="spacer"></div>
  <a mat-button color="primary" (click)="backToList()"><mat-icon>navigate_before</mat-icon> Back to Manage Documents</a>
</header>
<hh-messages id="error_box" *ngIf="errorMessages.length > 0" [title]="errorTitle" messageType="alert" [messages]="errorMessages"></hh-messages>
<hh-messages id="success_box" *ngIf="successText !== ''" [title]="successTitle" [messages]="[successText]"></hh-messages>

<section class="sub-container sub-container-inline">
  <h3>Document Information</h3>
  <ng-container *ngIf="loadingDocument">
    <div class="document-tag-loading-container"><span class="document-tag-loading">Loading...</span></div>
  </ng-container>
  <ng-container *ngIf="!loadingDocument">
    <div class="document-tag-owner">
      <div id="document-owner" class="information-row"><h4>Owner: </h4>{{document?.fullName}}</div>
      <div id="document-owner-email" class="information-row"><h4>Email: </h4>{{document?.email}}</div>
      <div id="document-status" class="information-row"><h4>Document Status: </h4>{{document?.document?.documentStatusType?.description}}</div>
      <div id="document-type" class="information-row"><h4>Document File Type: </h4>{{document?.document?.documentFileType?.description}}</div>
    </div>
  </ng-container>
</section>
<section class="sub-container sub-container-inline">
  <h3>Tags</h3>
  <ng-container *ngIf="loadingDocumentTags">
    <div class="document-tag-loading-container"><span class="document-tag-loading">Loading...</span></div>
  </ng-container>
  <ng-container *ngIf="!loadingDocumentTags">
    <div class="document-tag-management-container">
      <ng-container *ngFor="let item of documentTags">
        <div class="information-row document-tag-row">
          <div class="item-left">{{item.documentTagType.description}}</div>
          <div class="item-right">
            <button mat-icon-button aria-label="Remove tag" (click)="(deleteDocumentTag(item.id))">
              <mat-icon class="document-tag-remove-icon" color="primary">highlight_off</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loadingDocumentTagTypes && documentTagTypes && documentTagTypes.length > 0">
        <div class="add-tag-container">
          <form [formGroup]="form">
            <mat-form-field class="add-document-tag-form-field">
              <mat-label>Tag Type</mat-label>
              <mat-select id="documentTagTypeSelector" formControlName="documentTagTypeId" [attr.disabled]="true" required>
                <mat-option *ngFor="let tagType of documentTagTypes" [value]="tagType.id">{{ tagType.description }}</mat-option>
              </mat-select>
              <mat-error>
                <hh-validation-error message="Please select a tag type to add to this document."></hh-validation-error>
              </mat-error>
            </mat-form-field>
            <button id="tagTypeAddButton" mat-flat-button [disabled]="(processingTagOperation)" color="primary" class="add-tag-button" (click)="(addDocumentTag())">
              Add Tag
            </button>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="processingTagOperation">
        <div class="tag-operation-container primary">
          {{ tagOperationText }}
        </div>
      </ng-container>
    </div>
  </ng-container>
</section>
