export class SaveEvent<T> {
  constructor(
    public saveType: SaveType,
    public data?: T,
    public message: string = ''){}
}

export enum SaveType {
  ADD,
  EDIT,
  ERROR
}