<h3 class="no-border">Find existing Agent by:</h3>
<hh-messages id="messages_box" *ngIf="errored" [title]="messageTitle" [messageType]="messageType" [messages]="errorMessages"></hh-messages>
<form [formGroup]="form" (ngSubmit)="search()" class="flex full">
    <div class="flex vertical half">
        <div class="flex">
            <div class="column">
                <mat-form-field>
                    <input data-test-id="firstName" matInput placeholder="First Name" formControlName="firstName">
                    <mat-error>
                      <hh-validation-error [message]="firstNameError"></hh-validation-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="column">
                <mat-form-field>
                    <input data-test-id="lastName" matInput placeholder="Last Name" formControlName="lastName">
                    <mat-error>
                      <hh-validation-error [message]="lastNameError"></hh-validation-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <mat-form-field>
            <input matInput data-test-id="phoneNumber" placeholder="Phone Number" formControlName="phoneNumber">
            <mat-error>
              <hh-validation-error [message]="phoneError"></hh-validation-error>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="column">
        <button data-test-id="submitButton" mat-stroked-button type="submit" [disabled]="isSearching">
            <i class="fas fa-search"></i> Search
            <i class="fas fa-spinner fa-pulse" *ngIf="isSearching"></i>
        </button>
    </div>
</form>
<hh-messages id="messageBox" [messageType]="areAgentsFound ? 'success' : 'alert'" [title]="title" [messages]="messages">
    <br>
    <div *ngIf="hasSearched && areAgentsFound">
            <form (ngSubmit)="searchChanged()">
                <div class="table-search-wrapper">
                    <mat-form-field>
                        <input name="search-query" matInput
                            placeholder="Filter Agent/Agency by..."
                            [(ngModel)]="filterQuery">
                    </mat-form-field>
                    <button type="submit" id="search-button" mat-button><i class="fa fa-search"></i></button>
                </div>
            </form>
            <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef class="mat-column-select"></mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-select">
                        <mat-radio-button color="primary" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"></mat-radio-button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="agent_name">
                    <mat-header-cell *matHeaderCellDef>Agent Name</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="Agent Name">{{row.firstName}} {{row.lastName}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="agency_name">
                    <mat-header-cell *matHeaderCellDef>Agency Name</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="Agency Name">{{row.agency?.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="City">{{row.address?.city}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="State">{{getStateName(row.address?.state)}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="defaults.pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
            <div class="none-wrapper">
                <mat-radio-button color="primary" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(emptyAgent) : null"
                [checked]="selection.isSelected(emptyAgent)">None of the above</mat-radio-button>
            </div>
        </div>
</hh-messages>
