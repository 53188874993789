import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from '../dialogs/dialog.service';
import { IHasChanges } from '../models/component.model';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<IHasChanges> {
  constructor(private dialogService: DialogService) { }

  canDeactivate(
    component: IHasChanges, 
    currentRoute: ActivatedRouteSnapshot, 
    currentState: RouterStateSnapshot, 
    nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      if (component.hasChanges()) {
        return this.dialogService.openUnsavedChangesDialog();
      }
  
      return true;
  }
}