import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { Harvest } from 'src/app/models/harvest.model';
import { HarvestService } from 'src/app/services/harvest.service';
import { RmaService } from 'src/app/services/rma.service';
import { State } from 'src/app/models/rma.model';
import { getHarvestOwnerName } from 'src/app/models/utility';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { ConfirmationDialogOptions } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ReviewService } from 'src/app/services/review.service';
import { HarvestDetailsComponent } from './harvest-details/harvest-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IHasChanges } from 'src/app/models/component.model';
import { MessageContent } from 'src/app/messages/messages.component';
import { AppEvent, EventBroadcastService } from 'src/app/services/event-broadcast.service';
import { createMessageContent } from 'src/app/utility';
import { HarvestOwner } from 'src/app/models/harvest-owner.model';
import { Address } from 'src/app/models/address.model';
import { HarvestOwnerService } from 'src/app/services/harvest-owner.service';
import { map, mergeMap } from 'rxjs/operators';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'hh-user-harvests',
  templateUrl: './user-harvests.component.html',
  styleUrls: ['./user-harvests.component.scss']
})
export class UserHarvestsComponent implements OnInit, IHasChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(HarvestDetailsComponent) harvestDetails!: HarvestDetailsComponent;

  search: string ='';
  userId: string = '';
  reviewId: string = '';
  dataSource: MatTableDataSource<Harvest> = new MatTableDataSource<Harvest>();
  harvest?:Harvest;
  loading: boolean = false;
  loadHarvest: boolean = false;
  loadReviewHarvests: boolean = false;
  loadHarvestId: string = '';
  loadHarvestOwnerId: string = '';
  boundaryRedirectIndicator: boolean = false;
  states: State[] = [];

  constructor(
    private harvestService: HarvestService,
    private harvestOwnerService: HarvestOwnerService,
    private rmaService: RmaService,
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private broadcastService: EventBroadcastService,
    private route: ActivatedRoute,
    private router: Router) { }

  hasChanges(): boolean {
    return this.harvestDetails ? this.harvestDetails.hasChanges() : false;
  }

  markAsClean(): void {
    this.harvest = undefined;
  }

  get noDataText(): string {
    if (this.loading) {
      return 'Loading...'
    } else {
      if (this.dataSource.data.length === 0) {
        return 'No Harvests to display';
      } else if (this.dataSource.filteredData.length === 0) {
        return 'No Harvests match your search';
      }
    }

    return '';
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      this.reviewId = params['reviewId'];
      this.loadHarvestId = params['harvestId'];
      this.loadHarvestOwnerId = params['harvestOwnerId'];
      let boundaryRedirect = params['manageBoundaries'];
      if(boundaryRedirect && this.loadHarvestId && this.loadHarvestId !== '' && this.loadHarvestOwnerId && this.loadHarvestOwnerId !== '') {
        this.boundaryRedirectIndicator = true;
      }
      else if(this.reviewId && this.reviewId !== '') {
        this.loadReviewHarvests = true;
      }
      else if(this.loadHarvestId && this.loadHarvestId !== '' && this.loadHarvestOwnerId && this.loadHarvestOwnerId !== '') {
        this.loadHarvest = true;
      }
      this.getHarvests();
    })
  }

  getHarvests() {
    this.loading = true;

    if(this.boundaryRedirectIndicator) {
      forkJoin({
        harvest: this.harvestService.getHarvestById(this.loadHarvestId, true),
        harvestOwner: this.harvestOwnerService.getHarvestOwnerById(this.loadHarvestOwnerId)
      }).subscribe(value => {
          this.harvest = value.harvest;
          this.harvest.harvestOwner = value.harvestOwner;
          this.loading = false;
          this.manageBoundaries(value.harvest)
        },
        error => {
          this.loading = false;
          this.raiseError(error);
      });
    }
    else if(this.loadReviewHarvests) {
      forkJoin({
        harvests: this.harvestService.getHarvestsForReview(this.reviewId),
        // TODO: Add Reinsurance Year for getCropTypes
        cropTypes: this.rmaService.getCropTypes(),
        // TODO: Add Reinsurance Year for getStates
        states: this.rmaService.getStates()
      }).subscribe(value => {
          this.states = value.states;
          let harvests = value.harvests.map(h => {
            const cropType = value.cropTypes.find(ct => ct.id === h.commodityId);
            const state = this.states.find(s => s.id === h.state);
            h.commodityId = cropType?.id ?? '';
            h.commodityName = cropType?.display ?? 'N/A';
            h.state = state?.name ?? '';
            return h;
          });

          this.dataSource = new MatTableDataSource(harvests);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'harvestOwner': return this.getHarvestOwnerName(item.harvestOwner!);
              default: return (item as any)[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (item, filter) => {
            filter = filter.toLowerCase();
            return item.state.toLowerCase().includes(filter) ||
              item.commodityId.toLowerCase().includes(filter) ||
              this.getHarvestOwnerName(item.harvestOwner!).toLowerCase().includes(filter);
          };

          this.loading = false;
        },
        error => {
          this.loading = false;
          this.raiseError(error);
      });
    }
    else if(this.loadHarvest) {
      forkJoin({
        harvest: this.harvestService.getHarvestById(this.loadHarvestId, true),
        harvestOwner: this.harvestOwnerService.getHarvestOwnerById(this.loadHarvestOwnerId)
      }).subscribe(value => {
          value.harvest.harvestOwner = value.harvestOwner
          value.harvest.commodityName = value.harvest.commodityRecord?.name ?? 'N/A';
          value.harvest.state = value.harvest.stateRecord?.name ?? 'N/A';
          this.dataSource = new MatTableDataSource([value.harvest]);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'harvestOwner': return this.getHarvestOwnerName(item.harvestOwner!);
              default: return (item as any)[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (item, filter) => {
            filter = filter.toLowerCase();
            return item.state.toLowerCase().includes(filter) ||
              item.commodityId.toLowerCase().includes(filter) ||
              this.getHarvestOwnerName(item.harvestOwner!).toLowerCase().includes(filter);
          };

          this.loading = false;
          this.manageHarvestDetails(value.harvest)
        },
        error => {
          this.loading = false;
          this.raiseError(error);
      });
    }
    else {
      forkJoin({
        //TODO talk to Justin about this, I think we want both types of harvests as we automatically create reviews where needed
        harvests: this.harvestService.getHarvestsForUser(this.userId),
        // TODO: Add Reinsurance Year for getCropTypes
        cropTypes: this.rmaService.getCropTypes(),
        // TODO: Add Reinsurance Year for getStates
        states: this.rmaService.getStates()
      }).subscribe(value => {
          this.states = value.states;
          let harvests = value.harvests.map(h => {
            const cropType = value.cropTypes.find(ct => ct.id === h.commodityId);
            const state = this.states.find(s => s.id === h.state);
            h.commodityId = cropType?.id ?? '';
            h.commodityName = cropType?.display ?? 'N/A';
            h.state = state?.name ?? '';
            return h;
          });

          this.dataSource = new MatTableDataSource(harvests);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'harvestOwner': return this.getHarvestOwnerName(item.harvestOwner!);
              default: return (item as any)[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (item, filter) => {
            filter = filter.toLowerCase();
            return item.state.toLowerCase().includes(filter) ||
              item.commodityId.toLowerCase().includes(filter) ||
              this.getHarvestOwnerName(item.harvestOwner!).toLowerCase().includes(filter);
          };

          this.loading = false;
        },
        error => {
          this.loading = false;
          this.raiseError(error);
      });
    }

  }

  getHarvestOwnerName = getHarvestOwnerName;

  filterHarvests() {
    this.dataSource.filter = this.search;
  }

  harvestSaved(harvest: Harvest | undefined) {
    if (harvest === undefined) {
      this.harvest = undefined;
    } else {
      let data = [...this.dataSource.data];
      for(let item of data) {
        if (item.harvestOwner?.id === harvest.harvestOwner?.id) {
          item.harvestOwner = harvest.harvestOwner;
        }
      }
      this.dataSource.data = data;
    }
  }

  manageHarvestDetails(harvest: Harvest) {
    // if harvest has been set, the route guard won't check for unsaved changes because the outlet doesn't deactivate
    if (this.harvest && this.harvestDetails && this.harvestDetails.hasChanges()) {
      this.dialogService.openUnsavedChangesDialog()
        .subscribe(doLeave => {
          if (doLeave) {
            this.openHarvestOwnerDialog(harvest);
          }
        });

      return;
    }

    this.openHarvestOwnerDialog(harvest);
  }

  manageBoundaries(harvest: Harvest) {
    this.reviewService.createReviewForHarvest(harvest!.id!)
      .subscribe(result => {
        this.router.navigate([{outlets: {'harvests': ['harvest', result.id, 'boundaries']}}], {relativeTo: this.route});
      },
      this.raiseError.bind(this));
  }

  private openHarvestOwnerDialog(harvest: Harvest): void {
    this.dialogService.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Manage Harvest', 'All edits to the Harvest Owner are universal', 'Cancel', 'OK')
    }).subscribe(value => {
      if (value) {
        this.reviewService.createReviewForHarvest(harvest!.id!).subscribe(value => {
          this.harvest = value;
        },
        this.raiseError.bind(this));
      }
    });
  }

  private raiseError(error: any): void {
    let title: string = '';
    let message: string = '';

    if (error.status) {
      const messageContent = createMessageContent(error.status);
      title = messageContent?.title ?? '';
      message = messageContent?.messages[0] ?? '';
    }
    else {
      title = 'Error';
      message = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
    }

    const msgContent: MessageContent = { title: title, messages: [message] };
    this.broadcastService.dispatch<MessageContent>(new AppEvent('message', msgContent));
  }
}
