<section class="sub-container">
  <hh-messages [messages]="messages" messageType="alert"></hh-messages>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <strong *ngIf="!county">Add Boundary</strong>
      <span *ngIf="county">
        <strong>Boundary:</strong>&nbsp;{{county.name}}
      </span>
    </mat-expansion-panel-header>
    <form [formGroup]="form" class="flex full">
      <div class="flex half">
        <div class="column">
          <mat-form-field>
            <mat-label>County</mat-label>
            <mat-select required formControlName="county">
              <mat-option *ngFor="let county of counties" [value]="county.id">{{county.name}}</mat-option>
            </mat-select>
            <mat-error>
              <hh-validation-error message="Select county."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field>
            <mat-label>Crop Insurance Plan</mat-label>
            <mat-select formControlName="planCode">
              <mat-option (onSelectionChange)="toggleCoverageLevel(plan,$event)" *ngFor="let plan of plans" [value]="plan.id" >{{plan.display}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex half">
        <div class="column">
          <mat-form-field>
            <mat-label>Coverage Level</mat-label>
            <mat-select formControlName="level">
              <mat-option *ngFor="let level of levels" [value]="level">{{level  * 100 | number }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="column">
          &nbsp; <br/>
          <mat-checkbox formControlName="isCatastrophic" (change)="toggleLevelEnable($event)">CAT</mat-checkbox>
        </div>
      </div>
    </form>

    <h3 *ngIf="loading"><i class="fas fa-spinner fa-pulse"></i> Loading...</h3>
    <hh-data-table *ngIf="!loading"
      [columnDefinitions]="unitColumnDefinitions"
      [data]="units"
      [minimumRequired]="1"
      itemType="Unit"
      managedItemType="Boundary Information"
      (listChanged)="editList($event, listType.UNIT)"
    ></hh-data-table>
    <section class="arrowed-sub inlined-section no-margin-bottom" *ngIf="selectedUnit">
      <h3 class="no-padding-top"><strong>Unit:</strong> {{unitNumber}}</h3>
      <h3>Legal Descriptions</h3>
      <hh-messages messageType="info" title="NOTE" [messages]="['Legal Description must have all of Section-Township-Range']"></hh-messages>
      <hh-data-table
        [definition]="legalTableDefinition"
        [data]="legalDescriptions"
        [minimumRequired]="0"
        itemType="Legal Description"
        (listChanged)="editList($event, listType.LEGAL_DESCRIPTION)"
      ></hh-data-table>
    </section>
    <section class="arrowed-sub inlined-section no-margin-bottom" *ngIf="selectedUnit">
      <h3 class="no-padding-top"><strong>Unit:</strong> {{unitNumber}}</h3>
      <h3>CLUs</h3>
      <hh-messages messageType="info" title="NOTE" [messages]="['CLU must have all FSA fields filled']"></hh-messages>
      <hh-data-table
        [definition]="cluTableDefinition"
        [data]="clus"
        [minimumRequired]="0"
        itemType="CLU"
        (listChanged)="editList($event, listType.CLU)"
      ></hh-data-table>
    </section>
    <section class="arrowed-sub inlined-section no-margin-bottom" *ngIf="selectedUnit">
      <h3 class="no-padding-top"><strong>Unit:</strong> {{unitNumber}}</h3>
      <h3>Planting Information</h3>
      <hh-data-table
        [definition]="plantingTableDefinition"
        [data]="plantingInformation"
        [minimumRequired]="1"
        itemType="Planting Information"
        (listChanged)="editList($event, listType.PLANTING_INFORMATION)"
      ></hh-data-table>
    </section>

    <mat-action-row>
      <button mat-raised-button color="primary" (click)="save()">Save Boundary</button>
      <button mat-stroked-button (click)="cancel()">Cancel</button>
    </mat-action-row>
  </mat-expansion-panel>
</section>
