import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string): unknown {
    try {
      let cleaned = ('' + phoneValue).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
        return phoneValue;
    } catch (error) {
      return phoneValue;
    }
  }

}
