import { Component } from '@angular/core';

@Component({
  selector: 'hh-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent  {

}
