<div class="sys-message" [ngClass]="messageType" *ngIf="messages && messages.length > 0">
  <div>
    <i *ngIf="messageIcon" [ngClass]="messageIcon"></i>
  </div>
  <div>
      <p *ngIf="messageTitle && messageTitle.length > 0"><strong>{{messageTitle}}</strong></p>
      <p *ngIf="messages.length === 1" [innerHTML]="messages[0]"></p>
      <ul *ngIf="messages.length > 1">
          <li *ngFor="let msg of messages; last as isLast" [innerHTML]="msg"></li>
      </ul>
      <ng-content></ng-content>
  </div>
</div>