import { HarvestOwner } from 'src/app/models/harvest-owner.model';

export function getHarvestOwnerName(owner: HarvestOwner): string {
  if (owner.entityName) {
    return owner.entityName;
  }
  let name = '';
  if (owner.firstName) {
    name += owner.firstName;
  }

  if (owner.lastName) {
    if (name) {
      name += ' ';
    }

    name += owner.lastName;
  }
  return name;
}
