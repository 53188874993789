import { AbstractControl, Validators } from '@angular/forms';
import { initialConfig, IConfig } from 'ngx-mask';

export enum ColumnType {
  HIDDEN,
  TEXT,
  DATE,
  SELECT,
  CALCULATED
}

export enum AcceptedCharacters {
  Numeric,
  Alpha,
  AlphaNumeric,
  Any
}

class SelectOption {
  constructor(public value: any, public display: string) {  }

  static createYesNoOptions(): SelectOption[] {
    return [
      new SelectOption(true, 'Yes'),
      new SelectOption(false, 'No')
    ];
  }

  static createOptions<TItem>(items: TItem[], valueProperty: string, displayProperty: string): SelectOption[] {
    return items.map((item: any) => new SelectOption(item[valueProperty], item[displayProperty]));
  }
}

export class MaskConfiguration implements IConfig {
  static createNumberConfiguration(totalDigits: number, decimalDigits: number): MaskConfiguration {
    const config = new MaskConfiguration();

    const wholeDigits = totalDigits - decimalDigits;
    config.maskExpression = `separator.${decimalDigits}`;
    config.thousandSeparator = ',';
    config.separatorLimit = '1' + ('0'.repeat(wholeDigits - 1));

    return config;
  }

  static createTextConfiguration(acceptedCharacters: AcceptedCharacters, maximumLength: number = 0, minimumLength: number = 0) : MaskConfiguration {
      const config = new MaskConfiguration();

      let optionalCharacter = '';
      let requiredCharacter = '';
      if (acceptedCharacters === AcceptedCharacters.Numeric) {
        optionalCharacter = '9';
        requiredCharacter = '0'
      } else if (acceptedCharacters === AcceptedCharacters.Alpha) {
        optionalCharacter = 'C';
        requiredCharacter = 'S';
      } else if (acceptedCharacters === AcceptedCharacters.AlphaNumeric) {
        optionalCharacter = 'Z';
        requiredCharacter = 'A';
      }

      config.maskExpression = requiredCharacter.repeat(minimumLength)+optionalCharacter.repeat(maximumLength - minimumLength);

      return config;
  }

  maskExpression: string = '';

  suffix: string = initialConfig.suffix;
  prefix: string = initialConfig.prefix;
  thousandSeparator: string = ',';
  decimalMarker: '.' | ',' = initialConfig.decimalMarker;
  clearIfNotMatch: boolean = initialConfig.clearIfNotMatch;
  showTemplate: boolean = initialConfig.showTemplate;
  showMaskTyped: boolean = initialConfig.showMaskTyped;
  placeHolderCharacter: string = initialConfig.placeHolderCharacter;
  shownMaskExpression: string = initialConfig.shownMaskExpression;
  dropSpecialCharacters: boolean | string[] = initialConfig.dropSpecialCharacters;
  specialCharacters: string[] = initialConfig.specialCharacters;
  hiddenInput: boolean | undefined;
  validation: boolean = true;
  separatorLimit: string = initialConfig.separatorLimit;
  allowNegativeNumbers: boolean = initialConfig.allowNegativeNumbers;
  leadZeroDateTime: boolean = initialConfig.leadZeroDateTime;
  patterns: { [character: string]: { pattern: RegExp; optional?: boolean | undefined; symbol?: string | undefined; }; } = {
    ...initialConfig.patterns,
    'C': { pattern: /[a-zA-Z]/, optional: true},
    'Z': {pattern: /[a-zA-Z0-9]/, optional: true}
  };
}

export class DataTableDefinition {
  constructor(public columns: Column[], public requiredColumns?: MultipleColumnsRequiredDefinition) {}
}

export class MultipleColumnsRequiredDefinition {
  public get allColumnNames(): string[] {
    return this.allColumns.map(x => x.name);
  }

  private columnGroups: Column[][] = [];

  private get allColumns(): Column[] {
    return this.columnGroups.reduce((acc, value) => acc.concat(value), []);
  }

  private get singles(): Column[] {
    return this.columnGroups.filter(group => group.length === 1).reduce((acc, value) => acc.concat(value), []);
  }

  private get multi(): Column[][] {
    return this.columnGroups.filter(group => group.length > 1);
  }

  constructor(columns: Column | Column[]) {
    this.columnGroups.push(columns instanceof Array ? columns : [columns]);
  }

  public or = (columns: Column | Column[]): MultipleColumnsRequiredDefinition => {
    this.columnGroups.push(columns instanceof Array ? columns : [columns]);
    return this;
  };

  public getInvalidColumnNames(control: AbstractControl): string[] {
    // any singles met, no error
    if (this.singles.filter(column => this.isValid(control, column)).length > 0) {
      return [];
    }

    //any multiples met, no error
    if (this.multi.filter(columns => columns.reduce((acc: boolean, column) => acc && this.isValid(control, column), true)).length > 0) {
      return [];
    }

    // all empty
    if (this.allColumns.filter(column => this.isValid(control, column)).length === 0) {
      return [];
    }

    // return list of all invalids
    return this.allColumns.filter(column => !this.isValid(control, column)).map(column => column.name);
  }

  private isValid = (control: AbstractControl, column: Column): boolean => {
    const value = control.get(column.name)?.value ?? column.defaultValue;
    return value !== column.defaultValue;
  };
}

export class Column {
  required: boolean;
  readonly: boolean;
  sortable: boolean;
  unique: boolean;

  columnTypes: ColumnType[];
  defaultValue: any = '';

  /**
   * Creates a new Column used by DataTableComponent
   * @param name Name of the column. This needs to match a property on your data object if you want it displayed.
   * @param header Text to display in UI table header
   * @param options Defines whether column is required, readonly, sortable, and/or unique
   */
  public constructor(public name: string, public header: string = '', options: ColumnOptions = {}) {
    this.required = options.required ?? false;
    this.readonly = options.readonly ?? false;
    this.sortable = options.sortable ?? false;
    this.unique = options.unique ?? false;
    this.validationMessages['required'] = 'Required';

    if (this.unique) {
      this.validationMessages['unique'] = 'Duplicate';
    }

    this.columnTypes = this.required  || this.readonly || this.sortable || this.unique ? [ColumnType.TEXT] : [ColumnType.HIDDEN];
  }

  public validationMessages: { [name: string]: string } = {};
  public maskConfig: MaskConfiguration = new MaskConfiguration();
  public selectOptions?: SelectOption[];

  public calculate: {(value: any): any} = (value: any) => value;

  /**
   *
   * @param types
   * @returns `true` when column has one of the given types
   */
  is(...types: ColumnType[]): boolean {
    return this.columnTypes.reduce((res: boolean, type) => types.includes(type) || res, false);
  }

  /**
   *
   * @param acceptedCharacters Set of characters to be allowed: Alpha, Alphanumeric, Numeric, or Any
   * @param maxLength Maximum number of characters allowed
   * @param minLength Minimum number of characters allowed
   * @returns The column
   */
  asText(acceptedCharacters: AcceptedCharacters = AcceptedCharacters.Any,
    maxLength: number = 0,
    minLength: number = 0)
  {
    this.columnTypes = [ColumnType.TEXT];
    let lengthReq = ''
    if (minLength === 0) {
      lengthReq = `up to ${maxLength}`;
    } else if (minLength === maxLength) {
      lengthReq = maxLength + '';
    } else {
      lengthReq = `${minLength} - ${maxLength}`;
    }
    let accepted = ''
    switch (acceptedCharacters) {
      case AcceptedCharacters.Numeric:
        accepted = 'digit' + (maxLength > 1 ? 's' : '');
        break;
      case AcceptedCharacters.Alpha:
        accepted = 'letter' + (maxLength > 1 ? 's' : '');
        break;
      case AcceptedCharacters.AlphaNumeric:
        accepted = `letter${maxLength > 1 ? 's' : ''} and/or number${maxLength > 1 ? 's' : ''}`;
        break;
    }

    this.validationMessages['mask'] = `Enter ${lengthReq} ${accepted}`;
    if (acceptedCharacters !== AcceptedCharacters.Any) {
      this.maskConfig = MaskConfiguration.createTextConfiguration(acceptedCharacters, maxLength, minLength);
    }

    return this;
  }

  /**
   * Marks this column as a number column
   * @param totalDigits Total number of allowed digits, whole and decimal
   * @param decimalDigits Number of allowed decimal digits
   * @returns The column
   */
  asNumber(totalDigits: number, decimalDigits: number = 0) {
    this.columnTypes = [ColumnType.TEXT];
    this.maskConfig = MaskConfiguration.createNumberConfiguration(totalDigits, decimalDigits);

    return this;
  }

  /**
   * Marks this column as a date column
   * @returns The column
   */
  asDate() {
    this.columnTypes = [ColumnType.DATE];
    this.validationMessages['matDatepickerParse'] = 'Enter valid date';

    return this;
  }

  /**
   *
   * @param items List of items to turn into `SelectOption`s. If not passed in, options default to 'Yes' and 'No'
   * @param valueProperty
   * @param displayProperty
   * @returns The column
   */
  asSelect(items?: any[], valueProperty?: string, displayProperty?: string) {
    this.columnTypes = [ColumnType.SELECT];
    if (items && valueProperty && displayProperty) {
      this.selectOptions = SelectOption.createOptions(items, valueProperty, displayProperty);
    } else {
      this.selectOptions = SelectOption.createYesNoOptions();
    }

    return this;
  }

  /**
   * Adds a function to calculate the value of the column
   * @param calculate Function to determine the value of this column
   * @returns The column
   */
  withCalculation(calculate: {(value: any): any}){
    this.calculate = calculate;
    this.columnTypes.push(ColumnType.CALCULATED);

    return this;
  }

  withDefaultValue(value: any) {
    this.defaultValue = value;

    return this;
  }

  get formControl(): any[] | any {
    if (this.required) {
      return [{value: this.defaultValue, disabled: this.readonly}, [Validators.required]];
    }

    return { value: this.defaultValue, disabled: this.readonly }
  }
}

interface ColumnOptions {
  required?: boolean;
  readonly?: boolean;
  sortable?: boolean;
  unique?: boolean;
}

