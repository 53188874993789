<section>
  <mat-accordion>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <div data-test-id="unitNumberContainer">
          <strong>Unit:</strong> {{unitNumber}}
        </div>
      </mat-expansion-panel-header>
      <hh-messages id="messageBox" [title]="messageTitle" [messages]="messages" [messageType]="messageType"></hh-messages>
      <section class="arrowed-sub">
        <h3>Yield History</h3>
        <hh-data-table
          [columnDefinitions]="columnDefinitions"
          [data]="yields"
        ></hh-data-table>
      </section>
      <mat-action-row>
        <button data-test-id="saveButton" mat-flat-button color="primary" (click)="save()">Save</button>
        <button data-test-id="cancelButton" mat-stroked-button (click)="cancel()">Cancel</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</section>
