import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MultipleColumnsRequiredDefinition } from "src/app/data-table/models/data-table-definition.model";

export function MultipleColumnsRequiredValidator(definition: MultipleColumnsRequiredDefinition): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalidColumns = definition.getInvalidColumnNames(control);
    if (invalidColumns.length > 0) {
      return { 'required': invalidColumns}
    }

    return null;
  }
}