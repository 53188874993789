<mat-table [dataSource]="form.controls" matSort (matSortChange)="changeSort($event)">
  <ng-container *ngFor="let def of columns; index as i" [matColumnDef]="def.name">
    <mat-header-cell *matHeaderCellDef [disabled]="!def.sortable" mat-sort-header><span [class.required]="def.required">{{def.header}}</span></mat-header-cell>
    <mat-cell *matCellDef="let group; index as i">
      <span *ngIf="def.is(columnTypes.CALCULATED)">{{def.calculate(group.value) | mask: [def.maskConfig.maskExpression, def.maskConfig.patterns]}}</span>
      <mat-form-field [formGroup]="group" *ngIf="!def.is(columnTypes.CALCULATED) && !def.is(columnTypes.DATE) && !def.is(columnTypes.HIDDEN)">
        <input matInput *ngIf="def.is(columnTypes.TEXT)"
          [formControlName]="def.name"
          [mask]="def.maskConfig.maskExpression"
          [validation]="def.maskConfig.validation"
          [separatorLimit]="def.maskConfig.separatorLimit"
          [thousandSeparator]="def.maskConfig.thousandSeparator"
          [patterns]="def.maskConfig.patterns">
        <mat-select *ngIf="def.is(columnTypes.SELECT)" [formControlName]="def.name">
          <mat-option *ngFor="let option of def.selectOptions" [value]="option.value">{{option.display}}</mat-option>
        </mat-select>
        <mat-error>
          <hh-validation-error [message]="getErrorMessage(group, def)"></hh-validation-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="def.is(columnTypes.DATE) && !def.is(columnTypes.CALCULATED)" [formGroup]="group">
        <input matInput [matDatepicker]="picker" [formControlName]="def.name">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          <hh-validation-error [message]="getErrorMessage(group, def)"></hh-validation-error>
        </mat-error>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
    <mat-cell *matCellDef="let group; index as i">
      <span class="align-right">
        <a *ngIf="!group.value.added" mat-button color="primary" (click)="addItem(group)"><mat-icon>add_circle</mat-icon> Add {{itemType}}</a>
        <a *ngIf="group.value.added && managedItemType.length === 0" mat-button color="primary" (click)="deleteItem(i)"><mat-icon>remove_circle</mat-icon> Remove {{itemType}}</a>
        <button *ngIf="group.value.added && managedItemType.length > 0" mat-icon-button [mat-menu-trigger-for]="menu"><mat-icon>more_horiz</mat-icon></button>
      </span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item class="mat-menu-header">
          <p><strong>Available Actions</strong></p>
        </div>
        <a mat-menu-item (click)="selectItem(group, i)"><i class="fas fa-edit"></i> Manage {{managedItemType}}</a>
        <a mat-menu-item (click)="deleteItem(i)"><mat-icon color="primary">delete</mat-icon> Delete {{itemType}}</a>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
  <mat-row [class.selected]="selectedIndex === i" *matRowDef="let group; index as i; columns: displayColumns"></mat-row>
</mat-table>