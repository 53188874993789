import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Boundary } from '../models/boundary.model';
import { ServiceType } from '../models/type.model';
import { BaseService } from './base.service';
import { CountySearchParameters, RmaService } from './rma.service';

@Injectable({
  providedIn: 'root'
})
export class BoundaryService extends BaseService {

  constructor(http: HttpClient, private rmaService: RmaService) {
    super(http);
  }

  getBoundariesForHarvest(harvestId: string, includeRecords: boolean = false): Observable<Boundary[]> {
    if(includeRecords) {
      return forkJoin({
        boundaries: this.getMany<Boundary>(`harvest-history-api/documentreview/harvest/${harvestId}/coverage`),
      }).pipe(map(result => {
        result.boundaries.map(h => {
          this.rmaService.getCountyById(h.county).subscribe(county => {
            h.countyRecord = county;
          })
        });

        return result.boundaries;
      }));
    }
    else {
      return this.getMany<Boundary>(`harvest-history-api/documentreview/harvest/${harvestId}/coverage`);
    }
  }

  deleteBoundary(boundaryId: string): Observable<string> {
    return this.delete<string>(`harvest-history-api/documentreview/coverage/${boundaryId}`);
  }

  getBoundary(boundaryId: string, includeRecords: boolean = false, countyParams?: CountySearchParameters): Observable<Boundary> {
    if(includeRecords) {
      return forkJoin({
        boundary: this.get<Boundary>(`harvest-history-api/documentreview/coverage/${boundaryId}`)
      }).pipe(map(result => {
        this.rmaService.getCountyById(result.boundary.county).subscribe(county => {
          result.boundary.countyRecord = county
        })

        return result.boundary;
      }));
    }
    else {
      return this.get<Boundary>(`harvest-history-api/documentreview/coverage/${boundaryId}`);
    }
  }

  updateBoundary(boundary: Boundary): Observable<Boundary> {
    return this.put(`harvest-history-api/documentreview/coverage/${boundary.id!}`, boundary);
  }

  addBoundary(harvestId: string, boundary: Boundary): Observable<Boundary> {
    return this.post(`harvest-history-api/documentreview/harvest/${harvestId}/coverage`, boundary);
  }
}
