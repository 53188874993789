export class AIP {
  name: string;
  code: string;
  get display(): string {
    return `${this.name} (${this.code})`;
  };
  id: string;

  constructor( id: string, name: string, code: string ) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}

export class CropType {
  name: string;
  code: string;
  abbreviation: string;
  get display(): string {
    return `${this.name} (${this.code}) - ${this.commodityYear} `;
  };
  id: string;
  commodityYear: string;

  constructor(id: string, name: string, code: string, abbreviation: string, commodityYear: string ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.abbreviation = abbreviation;
    this.commodityYear = commodityYear;
  }
}

export class Practice {
  id: string;
  name: string;
  code: string;
  abbreviation: string;
  commodityCode: string;

  get display(): string {
    return `${this.name} (${this.code})`;
  };

  constructor(id: string, name: string, code: string, abbreviation: string, commodityCode: string ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.abbreviation = abbreviation;
    this.commodityCode = commodityCode;
  }
}

export class InsurancePlan {
  id: string;
  name: string;
  code: string;
  abbreviation: string = '';

  get display(): string {
    return `${this.abbreviation} (${this.code})`;
  }

  constructor(id: string, name: string, code: string, abbreviation: string ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.abbreviation = abbreviation;
  }
}

export class UnitStructure {
  id: string;
  name: string;
  code: string;

  get display(): string {
    return this.code;
  }

  constructor(id: string, name: string, code: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}

export class CoverageLevel {
  reinsuranceYear: string;
  coverageLevelPercent: number;
  coverageTypeCode: string;
  insurancePlanCode: string;

  constructor(reinsuranceYear: string,coverageLevelPercent: number, coverageTypeCode: string,insurancePlanCode: string ) {
    this.reinsuranceYear = reinsuranceYear;
    this.coverageLevelPercent = coverageLevelPercent;
    this.coverageTypeCode = coverageTypeCode;
    this.insurancePlanCode = insurancePlanCode;
  }
}

export class State {
  id: string;
  name: string;
  code: string;
  abbreviation: string;

  get display(): string {
    return this.name;
  }

  constructor(id: string, name: string, code: string, abbreviation: string ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.abbreviation = abbreviation;
  }
}

export class County {
  id: string;
  name: string;
  code: string;
  stateCode: string;

  get display(): string {
    return this.name;
  }

  constructor(id: string, name: string, code: string, stateCode: string ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.stateCode = stateCode;
  }
}

export class ReinsuranceYear {
  id: string;
  year: string;
  isActive: string;

  get display(): string {
    return this.year;
  }

  constructor(id: string, year: string, isActive: string) {
    this.id = id;
    this.year = year;
    this.isActive = isActive;
  }
}