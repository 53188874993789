<mat-table [dataSource]="form.controls">
  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index">
      <mat-form-field [formGroup]="element">
        <input matInput formControlName="firstName">
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index">
      <mat-form-field [formGroup]="element">
        <input matInput formControlName="lastName">
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="entityName">
    <mat-header-cell *matHeaderCellDef>Entity Name</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index">
      <mat-form-field [formGroup]="element">
        <input matInput formControlName="entityName">
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tinSuffix">
    <mat-header-cell *matHeaderCellDef>Last Four of TIN</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index">
      <mat-form-field [formGroup]="element">
        <input matInput mask="0000" formControlName="tinSuffix">
      </mat-form-field>
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index">
      <a *ngIf="showButton(i)" mat-button color="primary"(click)="doRowAction(i)">
        <mat-icon>{{getActionIcon(i)}}</mat-icon> {{ getActionText(i) }}
      </a>
    </mat-cell>
  </ng-container>
  

  <mat-header-row *matHeaderRowDef="['firstName', 'lastName', 'entityName', 'tinSuffix', 'actions']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['firstName', 'lastName', 'entityName', 'tinSuffix','actions']"></mat-row>
</mat-table>
