export enum ListChangeType {
  ADD,
  EDIT,
  DELETE,
  SELECT
}

export class ListChangeEvent {
  constructor(
    public changeType: ListChangeType,
    public index: number | null = null,
    public data: any = null) { }
}
