import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { RmaService } from 'src/app/services/rma.service';
import { AIP, CropType, ReinsuranceYear, State } from 'src/app/models/rma.model';
import { DocumentService } from 'src/app/services/document.service';
import { ServiceType } from 'src/app/models/type.model';
import { createMessageContent } from 'src/app/utility';
import { MessageContent } from 'src/app/messages/messages.component';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'hh-document-properties',
  templateUrl: './document-properties.component.html',
  styleUrls: ['./document-properties.component.scss']
})
export class DocumentPropertiesComponent implements OnInit {
  years: ReinsuranceYear[] = [];
  states: State[] = [];
  form: FormGroup;
  aips: AIP[] = [];
  cropTypeRecords: CropType[] = [];
  filteredCropTypes: Observable<CropType[]> = new Observable;
  selectedCropTypeIds: string[] = [];
  documentTypes: ServiceType[] = [];
  @Output() onError = new EventEmitter<MessageContent>();
  constructor(private builder: FormBuilder, private rmaService: RmaService, private documentService: DocumentService) {
    this.rmaService.getReinsuranceYears({ reinsuranceYearIsActiveFilter : "true" }).subscribe(res => this.years = res);
    this.form = builder.group({
      policyNumber: [{ value: '', disabled: true }, Validators.required],
      approvedInsuranceProvider: [{ value: '', disabled: true }, Validators.required],
      documentType: ['', Validators.required],
      year: ['', Validators.required],
      state: [{ value: '', disabled: true }, Validators.required],
      cropTypes: this.builder.array([
        [{ value: '', disabled: true }]
      ])
    });
  }

  ngOnInit(): void {
    this.manageCropTypeControl(0);
    this.form.controls['cropTypes'].disable();
  }

  manageCropTypeControl(index: number) {
    var arrayOfControls = this.form.get('cropTypes') as FormArray;
    this.filteredCropTypes = arrayOfControls!.at(index)!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): CropType[] {
    const filterValue = this._normalizedValue(value);
    const records = this.selectedCropTypeIds.length > 0 ? this.cropTypeRecords.filter(m => !this.selectedCropTypeIds.includes(m.id)) : this.cropTypeRecords;
    const priorityRecords = records.filter(cropType => this._normalizedValue(cropType.display).startsWith(filterValue));
    const filtered = records.filter(cropType => this._normalizedValue(cropType.display).includes(filterValue) && !priorityRecords.includes(cropType));
    return priorityRecords.concat(filtered);
  }

  private _normalizedValue(value: string): string {
    if (!value || !value.toLowerCase) return '';
    return value.toLowerCase().replace(/\s/g, '');
  }

  onCropYearChange(value: number) {
    var yearValue = value.toString()
    if(yearValue.length == 4) {
      this.loadDomains(yearValue)
      this.enableFormFields()
    }
  }

  onOptionSelected() {
    this.selectedCropTypeIds = [];
    this.cropTypesForm.controls.forEach(element => {
      this.selectedCropTypeIds.push(element.value.id)
    });
  }

  enableFormFields() {
    this.form.controls['policyNumber'].enable();
    this.form.controls['approvedInsuranceProvider'].enable();
    this.form.controls['state'].enable();
    this.form.controls['cropTypes'].enable();
  }

  loadDomains(year: string): void {
    forkJoin({
      documentTypes: this.documentService.getDocumentTypes(),
      aips: this.rmaService.getAIPs({ reinsuranceYearEqualsFilter: year }),
      cropTypes: this.rmaService.getCropTypes({ reinsuranceYearEqualsFilter: year }),
      states: this.rmaService.getStates({ reinsuranceYearEqualsFilter: year })
    }).subscribe(
      res => {
        this.documentTypes = res.documentTypes;
        this.aips = res.aips;
        this.cropTypeRecords = res.cropTypes;
        this.states = res.states;
      },
      error => {
        if (error.status) {
          const message = createMessageContent(error.status);
          this.onError.emit({title:  message?.title ?? '', messages: [message?.messages[0] ?? '']});
        }
        else {
          this.onError.emit({title:  '', messages: ['An unknown error occurred. Try refreshing this page, or navigate to the home page.']});
        }
      }
    );
  }

  get cropTypesForm() {
    return this.form.get('cropTypes') as FormArray;
  }

  getCropList(index: number): CropType[] {
    var types = this.cropTypeRecords ?? [];
    var selectedTypes: string[] = [...this.cropTypesForm.value];
    selectedTypes.splice(index, 1);
    types = types.filter(x => !selectedTypes.includes(x.code));
    return types;
  }

  addCropType() {
    this.cropTypesForm.push(new FormControl('', Validators.required));
    this.manageCropTypeControl(this.cropTypesForm.controls.length - 1)
  }

  removeCropType() {
    var idx = this.cropTypesForm.controls.length - 1;
    this.cropTypesForm.removeAt(idx);
    this.onOptionSelected();
  }

  markAsTouched() {
    this.form.markAllAsTouched();
  }

  cropTypeDisplay(ct: any): string {
    return ct && ct.display ? ct.display : '';
  }

  get touched(): boolean {
    return this.form.touched;
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get selectedCropTypeIdsArray(): string[] {
    return this.selectedCropTypeIds;
  }

  get value(): any {
    let value = this.form.value;
    let prodReportType = this.documentTypes.filter(x => x.type == 'ProductionReport')[0];
    if (value.documentType === prodReportType!.id) {
      value.year = (parseInt(value.year, 10) - 1) + '';
    }
    return this.form.value;
  }
}
