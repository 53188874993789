<h3>{{addressType}} Address</h3>
<div *ngIf="showSameAsCheckbox">
  <mat-checkbox data-test-id="addressSameAs" color="primary" [checked]="_sameAsOther" (change)="markSameAsOther($event)">Same as {{sameAsAddressType}}</mat-checkbox>
</div>
<form [formGroup]="form" *ngIf="!_sameAsOther" class="flex full">
  <div class="flex full vertical">
    <div class="flex full">
      <mat-form-field>
        <input matInput data-test-id="address1" placeholder="Address 1" required formControlName="address1">
      </mat-form-field>
    </div>
    <div class="flex full">
      <mat-form-field>
        <input matInput data-test-id="address2" placeholder="Address 2" formControlName="address2">
      </mat-form-field>
    </div>
    <div class="flex horiztonal">
      <div class="flex column">
        <mat-form-field>
          <input matInput data-test-id="city" placeholder="City" required formControlName="city">
        </mat-form-field>
      </div>
      <div class="flex column">
        <div class="flex column">
          <mat-form-field>
            <mat-label>State</mat-label>
            <mat-select data-test-id="state" required formControlName="state">
              <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex column">
          <mat-form-field>
            <input matInput data-test-id="postalCode" mask="00000" placeholder="ZIP" required formControlName="postalCode">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
