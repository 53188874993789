import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { forkJoin } from 'rxjs';
import { DataTableComponent } from 'src/app/data-table/data-table.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { Boundary, CLU, LegalDescription, PlantingInformation, Unit, UnitYield } from 'src/app/models/boundary.model';
import { AcceptedCharacters, Column, DataTableDefinition, MultipleColumnsRequiredDefinition } from 'src/app/data-table/models/data-table-definition.model';
import { IError } from 'src/app/models/error.model';
import { Guid } from 'src/app/models/guid';
import { ListChangeEvent, ListChangeType } from 'src/app/data-table/models/list-change-event.model';
import { SaveEvent, SaveType } from 'src/app/models/save-event.model';
import { BoundaryService } from 'src/app/services/boundary.service';
import { RmaService } from 'src/app/services/rma.service';
import { County, CoverageLevel, InsurancePlan, UnitStructure } from 'src/app/models/rma.model';
import { Harvest } from 'src/app/models/harvest.model';
import { HarvestService } from 'src/app/services/harvest.service';
import { map, mergeMap } from 'rxjs/operators';

enum ListType {
  UNIT,
  LEGAL_DESCRIPTION,
  CLU,
  PLANTING_INFORMATION
}

@Component({
  selector: 'hh-add-edit-boundary',
  templateUrl: './add-edit-boundary.component.html',
  styleUrls: ['./add-edit-boundary.component.scss']
})
export class AddEditBoundaryComponent implements OnInit {
  @ViewChildren(DataTableComponent) dataTables?: QueryList<DataTableComponent>;
  county?: County | null;
  units: Unit[] = [];
  currentboundary?: Boundary | null;
  messages: string[] = [];
  //harvest?: Harvest | null;
  @Input()
  set boundaryId(boundaryId: string | undefined) {
    if (boundaryId !== null && boundaryId !== undefined && boundaryId !== Guid.EMPTY) {
      this.getBoundary(boundaryId);
    }
    else {
      this.form.patchValue({ id: Guid.EMPTY, county: '', planCode: '', level: '', isCatastrophic: false });
      //this.setLevelEnabled(true);
      this.units = [];
    }
  }

  @Input() harvestId: string = '';
  @Input() harvest?: Harvest;
  @Input() year?: string = '';

  @Output() canceled = new EventEmitter();
  @Output() saved = new EventEmitter<SaveEvent<Boundary>>();

  form!: FormGroup;
  plans: InsurancePlan[] = [];
  coverageLevels: CoverageLevel[] = [];
  structures: UnitStructure[] = [];
  @Input() counties: County[] = [];
  levels: number[] = [];

  unitColumnDefinitions: Column[] = [];
  private selectedUnitIndex: number | null = null;

  legalColumnDefinitions: Column[] = [
    new Column('id'),
    new Column('section', 'Section').asText(AcceptedCharacters.Numeric, 4, 3),
    new Column('township', 'Township').asText(AcceptedCharacters.AlphaNumeric, 4, 4),
    new Column('range', 'Range').asText(AcceptedCharacters.AlphaNumeric, 4, 4)
  ];
  legalTableDefinition = new DataTableDefinition(this.legalColumnDefinitions,
    new MultipleColumnsRequiredDefinition(this.legalColumnDefinitions.slice(1, 3)).or([this.legalColumnDefinitions[3]]));
  private selectedLegalDescIndex: number | null = null;

  cluColumnDefinitions: Column[] = [
    new Column('id'),
    new Column('fsn', 'FSN').asText(AcceptedCharacters.Numeric, 4, 3),
    new Column('tract', 'Tract').asText(AcceptedCharacters.Numeric, 5, 3),
    new Column('field', 'Field').asText(AcceptedCharacters.Numeric, 2, 1),
    new Column('subField', 'Sub Field').asText(AcceptedCharacters.AlphaNumeric, 1, 1)
  ];
  cluTableDefinition = new DataTableDefinition(this.cluColumnDefinitions,
    new MultipleColumnsRequiredDefinition(this.cluColumnDefinitions.slice(1, 4)).or([this.cluColumnDefinitions[4]]));
  private selectedCluIndex: number | null = null;

  plantingInformationColumnDefinitions: Column[] = [
    new Column('id'),
    new Column('year', 'Year', { required: true, unique: true }).asText(AcceptedCharacters.Numeric, 4, 4),
    new Column('plantedDate', 'Plant Date').asDate(),
    new Column('acres', 'Planted Acres').asNumber(7, 2)
  ];
  plantingTableDefinition = new DataTableDefinition(this.plantingInformationColumnDefinitions,
    new MultipleColumnsRequiredDefinition(this.plantingInformationColumnDefinitions.slice(1, 3)).or([this.plantingInformationColumnDefinitions[3]]));
  private selectedPlantingIndex: number | null = null;

  listType = ListType;

  loading: boolean = true;

  constructor(builder: FormBuilder, private rmaService: RmaService, private boundaryService: BoundaryService, private dialog: DialogService, private harvestService: HarvestService) {
    this.form = builder.group({
      id: '',
      county: ['', Validators.required],
      planCode: '',
      level: [{ value: '', disabled: false }],
      isCatastrophic: false
    });

    this.levels = [];
  }

  ngOnInit(): void {
    this.loading = true;

    if (!this.year) {
      this.year = new Date().getFullYear().toString();
    }

    if(this.harvest) {
      this.harvestService.getReviewDocumentYear(this.harvestId)
      .pipe(
        map(year => {
          this.year = year;
        }),
        mergeMap(year => {
          return forkJoin({
            unitStructures: this.rmaService.getUnitStructures({ reinsuranceYearEqualsFilter: this.year }),
            practiceTypes: this.rmaService.getPractices( { reinsuranceYearEqualsFilter: this.year, commodityCodeEqualsFilter: this.harvest?.commodityRecord?.code } ),
            plans: this.rmaService.getInsurancePlans({ reinsuranceYearEqualsFilter: this.year })
          });
        })
      ).subscribe(result => {
        this.loading = false;

        this.plans = result.plans;
        this.structures = result.unitStructures;
        this.unitColumnDefinitions = [
          new Column('id'),
          new Column('farmName', 'Farm Name').asText(),
          new Column('basicUnitNumber', 'Basic Unit #', { required: true }).asText(AcceptedCharacters.Numeric, 4, 4),
          new Column('optionalUnitNumber', 'Optional Unit #', { required: true }).asText(AcceptedCharacters.Numeric, 4, 4),
          new Column('unitStructure', 'Unit Structure').asSelect(result.unitStructures, 'id', 'display'),
          new Column('practiceTypeId', 'Practice Type', { required: true }).asSelect(result.practiceTypes, 'id', 'name'),
          new Column('highRiskLand', 'High Risk Land').asSelect().withDefaultValue(null)
        ];
      });
    }
    else {
      forkJoin({
        harvest: this.harvestService.getHarvestById(this.harvestId, true),
        year: this.harvestService.getReviewDocumentYear(this.harvestId)
      })
      .pipe(
        map(values => {
          this.harvest = values.harvest;
          this.year = values.year;
        }),
        mergeMap(values => {
          return forkJoin({
            unitStructures: this.rmaService.getUnitStructures({ reinsuranceYearEqualsFilter: this.year }),
            practiceTypes: this.rmaService.getPractices( { reinsuranceYearEqualsFilter: this.year, commodityCodeEqualsFilter: this.harvest?.commodityRecord?.code } ),
            plans: this.rmaService.getInsurancePlans({ reinsuranceYearEqualsFilter: this.year })
          });
        })
      ).subscribe(result => {
        this.loading = false;

          this.plans = result.plans;
          this.structures = result.unitStructures;
          this.unitColumnDefinitions = [
            new Column('id'),
            new Column('farmName', 'Farm Name').asText(),
            new Column('basicUnitNumber', 'Basic Unit #', { required: true }).asText(AcceptedCharacters.Numeric, 4, 4),
            new Column('optionalUnitNumber', 'Optional Unit #', { required: true }).asText(AcceptedCharacters.Numeric, 4, 4),
            new Column('unitStructure', 'Unit Structure').asSelect(result.unitStructures, 'id', 'display'),
            new Column('practiceTypeId', 'Practice Type', { required: true }).asSelect(result.practiceTypes, 'id', 'name'),
            new Column('highRiskLand', 'High Risk Land').asSelect().withDefaultValue(null)
          ]
        });
    }
    if (this.currentboundary && this.harvest) {
      this.getLevels(this.currentboundary.isCatastrophic ? "C" : "A", this.currentboundary.planCode, this.harvest)
    }
    else {
      this.getLevels(undefined, undefined, this.harvest)
    }
  }

  editList(event: ListChangeEvent, listType: ListType) {
    let list: any[] = this.getList(listType);
    if (event.changeType === ListChangeType.ADD) {
      const newItem: any = { ...this.getNewItem(listType) };
      for (let prop in newItem) {
        if (event.data.hasOwnProperty(prop)) {
          newItem[prop] = event.data[prop];
        }
      }
      list.push(newItem);
    }
    else if (event.changeType === ListChangeType.EDIT) {
      for (let prop in list[event.index!]) {
        if (event.data.hasOwnProperty(prop)) {
          list[event.index!][prop] = event.data[prop]
        }
      }
    }
    else if (event.changeType === ListChangeType.SELECT) {
      this.changeIndex(listType, event.index);
    }
    else if (event.changeType === ListChangeType.DELETE) {
      list.splice(event.index!, 1);
      this.deleteChangeIndex(listType, event.index!);
    }
  }

  cancel() {
    const tablesTouched = this.dataTables?.reduce((prev, curr) => prev || curr.touched, false);
    if (this.form.touched || tablesTouched) {
      this.dialog.openUnsavedChangesDialog().subscribe(doCancel => {
        if (doCancel) {
          this.canceled.emit();
        }
      })
    } else {
      this.canceled.emit();
    }
  }

  save() {
    const tablesValid = this.dataTables?.reduce((prev, curr) => prev && curr.valid, true);
    const unitsWithoutLegals = this.units.filter(x => x.legalDescriptions.length === 0).length;
    if (!this.form.valid || !tablesValid || this.units.length === 0 || unitsWithoutLegals > 0) {
      this.form.markAllAsTouched();
      this.dataTables?.forEach(item => item.markAsTouched());
      if (this.units.length === 0 || unitsWithoutLegals > 0) {
        let type = 'unit';
        let parentType = 'boundary';
        if (unitsWithoutLegals > 0) {
          type = 'legal description';
          parentType = 'unit';
        }
        this.messages = [`Please add at least one ${type} for ${parentType}`];
      } else {
        this.messages = ['Enter required fields'];
      }
      return;
    }

    const boundary = { ...this.form.value, units: [...this.units] } as Boundary;
    if (this.form.value.id === Guid.EMPTY) {
      let saveType = SaveType.ADD;
      this.boundaryService.addBoundary(this.harvestId, boundary).subscribe(
        result => this.afterSave(result, saveType),
        error => this.onError(error, saveType));
    } else {
      let saveType = SaveType.EDIT;
      this.boundaryService.updateBoundary(boundary).subscribe(
        result => this.afterSave(result, saveType),
        error => this.onError(error, saveType));
    }
  }

  toggleLevelEnable(changeEvt: MatCheckboxChange) {
    var plan = this.form.get('planCode')?.value;
    var currentplan = this.plans.find(x => x.id == plan);
    var coverageTypeCode = changeEvt.checked ? "C" : "A";
    var filtered = this.coverageLevels.filter(x => x.coverageTypeCode == coverageTypeCode && x.insurancePlanCode == currentplan?.code);
    this.levels = [...new Set(filtered.map(item => item.coverageLevelPercent))];
  }

  toggleCoverageLevel(plan: InsurancePlan, event: any) {
    if (event.isUserInput) {
      var filtered = this.coverageLevels.filter(x => x.insurancePlanCode == plan.code);
      this.levels = [...new Set(filtered.map(item => item.coverageLevelPercent))];
      var cat = filtered.find(x => x.coverageTypeCode == "C");
      this.setLevelEnabled(cat)
    }
  }

  private setLevelEnabled(isCategory?: CoverageLevel) {
    const levelField = this.form.get('level');
    const catField = this.form.get('isCatastrophic');
    if (!isCategory) {
      catField?.reset();
      catField?.disable();
    }
    else {
      catField?.enable();
    }

    if(!this.currentboundary) {
      levelField?.setValue(this.levels[0]);
    }
    else {
      var currentLevelValue = this.currentboundary.level;

      if(!currentLevelValue || currentLevelValue === 0) {
        levelField?.setValue(this.levels[0]);
      }
      else {
        var levelRecord = this.levels.filter(m => m == currentLevelValue);

        if(!levelRecord || levelRecord.length == 0) {
          levelField?.setValue(this.levels[0]);
        }
        else {
          levelField?.setValue(levelRecord[0]);
        }
      }
    }
  }

  private afterSave(boundary: Boundary, eventType: SaveType) {
    this.saved.emit(new SaveEvent<Boundary>(eventType, boundary))
  }

  private onError(error: IError, eventType: SaveType) {
    let message = '';
    if (error.status === 404) {
      message = `${eventType === SaveType.ADD ? 'Harvest' : 'Coverage'} not found`;
    } else {
      message = error.error;
    }

    this.saved.emit(new SaveEvent<Boundary>(SaveType.ERROR, undefined, message));
    console.log(error);
  }

  private getBoundary(boundaryId: string) {
    this.boundaryService.getBoundary(boundaryId, false)
      .subscribe(boundary => {
        this.currentboundary = boundary;
        this.form.patchValue(boundary);
        this.county = this.counties.find(x => x.id === boundary.county) ?? null;
        this.units = boundary.units;
      })
  }

  private getLevels(coverageTypeCode?: string, insuranceplanCode?: string, harvest?: Harvest) {
    this.rmaService.getCoverageLevels({ reinsuranceYearEqualsFilter: this.year, commodityCodeEqualsFilter: harvest?.commodityRecord?.code, stateCodeEqualsFilter: harvest?.stateRecord?.code, commodityYearEqualsFilter: harvest?.commodityRecord?.commodityYear, coverageTypeCodeEqualsFilter: coverageTypeCode, insurancePlanCodeEqualsFilter: insuranceplanCode })
      .subscribe(coverages => {
        this.coverageLevels = coverages;
        this.levels = [];
        this.levels = [...new Set(coverages.map(item => item.coverageLevelPercent))];
        var cat = coverages.find(x => x.coverageTypeCode == "C");
        this.setLevelEnabled(cat)
      })
  }

  private getList(listType: ListType) {
    if (listType === ListType.UNIT) {
      return this.units;
    } else if (listType === ListType.LEGAL_DESCRIPTION) {
      return this.selectedUnit!.legalDescriptions;
    } else if (listType === ListType.CLU) {
      return this.selectedUnit!.clus;
    } else if (listType === ListType.PLANTING_INFORMATION) {
      return this.selectedUnit!.plantingInformation;
    }
    return [];
  }

  private getNewItem(listType: ListType) {
    if (listType === ListType.UNIT) {
      return {
        farmName: '',
        basicUnitNumber: '',
        optionalUnitNumber: '',
        unitStructure: '',
        practiceTypeId: '',
        highRiskLand: '',
        legalDescriptions: new Array<LegalDescription>(),
        clus: new Array<CLU>(),
        plantingInformation: new Array<PlantingInformation>(),
        yields: new Array<UnitYield>()
      };;
    } else if (listType === ListType.LEGAL_DESCRIPTION) {
      return {
        section: '',
        township: '',
        range: ''
      };
    } else if (listType === ListType.CLU) {
      return {
        fsn: '',
        tract: '',
        field: '',
        subField: ''
      };
    } else if (listType === ListType.PLANTING_INFORMATION) {
      return {
        year: '',
        plantedDate: null,
        acres: null
      };
    }

    return null;
  }

  private changeIndex(listType: ListType, index: number | null) {
    if (listType === ListType.UNIT) {
      this.selectedUnitIndex = index;
      this.selectedLegalDescIndex = null;
      this.selectedCluIndex = null;
      this.selectedPlantingIndex = null;
    } else if (listType === ListType.LEGAL_DESCRIPTION) {
      if(index) {
        this.selectedLegalDescIndex = index;
      }
    } else if (listType === ListType.CLU) {
      if(index) {
        this.selectedCluIndex = index;
      }
    } else if (listType === ListType.PLANTING_INFORMATION) {
      if(index) {
        this.selectedPlantingIndex = index;
      }
    }
  }

  private deleteChangeIndex(listType: ListType, index: number) {
    if (listType === ListType.UNIT && this.selectedUnitIndex === index) {
      this.changeIndex(listType, null);
    } else if (listType === ListType.LEGAL_DESCRIPTION && this.selectedLegalDescIndex === index) {
      this.changeIndex(listType, null);
    } else if (listType === ListType.CLU && this.selectedCluIndex === index) {
      this.changeIndex(listType, null);
    } else if (listType === ListType.PLANTING_INFORMATION && this.selectedPlantingIndex === index) {
      this.changeIndex(listType, null);
    }
  }

  get selectedUnit(): Unit | null {
    return this.selectedUnitIndex !== null ? this.units[this.selectedUnitIndex] : null;
  }

  get selectedLegalDesc(): LegalDescription | null {
    return this.selectedLegalDescIndex !== null ? this.selectedUnit!.legalDescriptions[this.selectedLegalDescIndex] : null;
  }

  get selectedClu(): CLU | null {
    return this.selectedCluIndex !== null ? this.selectedUnit!.clus[this.selectedCluIndex] : null;
  }

  get selectedPlantingInformation(): PlantingInformation | null {
    return this.selectedPlantingIndex !== null ? this.selectedUnit!.plantingInformation[this.selectedPlantingIndex] : null;
  }

  get touched(): boolean {
    const tablesTouched = this.dataTables?.reduce((prev, curr) => prev || curr.touched, false);
    return tablesTouched || this.form.touched;
  }

  get unitNumber(): string {
    let unitNumber = `${this.selectedUnit?.basicUnitNumber} - ${this.selectedUnit?.optionalUnitNumber}`;
    if (this.selectedUnit?.unitStructure) {
      let structure = this.structures.find(x => x.id === this.selectedUnit?.unitStructure);
      if (structure) {
        unitNumber += ` - ${structure.code}`;
      }
    }
    return unitNumber;
  }

  get legalDescriptions(): LegalDescription[] {
    return this.selectedUnit?.legalDescriptions ?? [];
  }

  get clus(): CLU[] {
    return this.selectedUnit?.clus ?? [];
  }

  get plantingInformation(): PlantingInformation[] {
    return this.selectedUnit?.plantingInformation ?? [];
  }
}
