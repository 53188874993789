import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SBI } from 'src/app/models/sbi.model';
import { HarvestOwner, OwnerType } from 'src/app/models/harvest-owner.model';
import { fieldHasError } from 'src/app/utility';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm } from '@angular/forms';
@Component({
  selector: 'hh-harvest-owner-details',
  templateUrl: './harvest-owner-details.component.html',
  styleUrls: ['./harvest-owner-details.component.scss']
})
export class HarvestOwnerDetailsComponent implements OnInit {
  ownerTypes = OwnerType;
  form: FormGroup = this.builder.group({
    id: '',
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    entityName: '',
    doingBusinessAs: '',
    emailAddress: ['', Validators.email],
    phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    tinSuffix: ['', [Validators.minLength(4), Validators.maxLength(4)]]
  })
  ownerType: OwnerType = OwnerType.Individual;
  matcher = new FormStateMatcher();
  @Input()
  set harvestOwner(value: HarvestOwner) {
    if (value) {
      this.fillForm(value)
    }
  }

  @Input()
  set harvestOwnerType(value: OwnerType) {
    this.changeOwnerType(value);
  }

  @Output() harvestOwnerTypeChange = new EventEmitter<OwnerType>();
  @Output() harvestOwnerTypeChangeWithFormData = new EventEmitter<{ ownerType: OwnerType, owner: HarvestOwner }>();
  @Output() ownerAsSBI = new EventEmitter<SBI>();

  constructor(private builder: FormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(value => {
      if (this.ownerType === OwnerType.Individual) {
        this.ownerAsSBI.emit({
          firstName: value.firstName,
          lastName: value.lastName,
          entityName: '',
          tinSuffix: value.tinSuffix,
          isEntity: false
        });
      }

      if (this.ownerType === OwnerType.Entity) {
        this.ownerAsSBI.emit({
          firstName: '',
          lastName: '',
          entityName: value.entityName,
          tinSuffix: value.tinSuffix,
          isEntity: true
        });
      }
    });
  }

  fillForm(owner: HarvestOwner) {
    if (owner !== undefined) {
      this.form.patchValue(owner);

      this.ownerType = owner.entityName != null && owner.entityName.length > 0 ? OwnerType.Entity : OwnerType.Individual;
    }
  }

  changeOwnerType(ownerType: OwnerType) {
    this.ownerType = ownerType;

    if (this.ownerType === OwnerType.Individual) {
      this.form.get('firstName')?.setValidators(Validators.required);
      this.form.get('lastName')?.setValidators(Validators.required);
      this.form.get('entityName')?.clearValidators();
      this.form.get('entityName')?.setErrors(null);
    } else {
      this.form.get('firstName')?.clearValidators();
      this.form.get('firstName')?.setErrors(null);
      this.form.get('lastName')?.clearValidators();
      this.form.get('lastName')?.setErrors(null);
      this.form.get('entityName')?.setValidators(Validators.required);
    }

    if(this.touched) {
      this.harvestOwnerTypeChangeWithFormData.emit({
        ownerType: this.ownerType,
        owner: { 
          ...this.harvestOwner, 
          firstName: this.form.controls['firstName'].value,
          lastName: this.form.controls['lastName'].value,
          entityName: this.form.controls['entityName'].value
        }
      })
    }
    else {
      this.harvestOwnerTypeChange.emit(this.ownerType);
    }
  }

  ownerTypeIs(ownerType: OwnerType): boolean {
    return this.ownerType === ownerType;
  }

  markAsTouched() {
    this.form.markAsTouched();
    this.form.get('firstName')?.updateValueAndValidity({onlySelf: true});
    this.form.get('lastName')?.updateValueAndValidity({onlySelf: true});
    this.form.get('entityName')?.updateValueAndValidity({onlySelf: true});
  }

  reset() {
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  get touched(): boolean {
    return this.form.touched && this.form.dirty;
  }

  get valid(): boolean {
    const ownerNameValid = this.ownerType === OwnerType.Individual ?
      ((this.form.get('firstName')?.valid ?? false) && (this.form.get('lastName')?.valid ?? false)) :
      (this.form.get('entityName')?.valid ?? false);
    return ownerNameValid &&
      (this.form.get('phoneNumber')?.valid ?? false) &&
      (this.form.get('emailAddress')?.valid ?? false) &&
      (this.form.get('tinSuffix')?.valid ?? false);
  }

  get value(): HarvestOwner {
    const value = (this.form.value as HarvestOwner);
    if (this.ownerTypeIs(OwnerType.Individual)) {
      value.isEntity = false;
      value.entityName = '';
    } else if (this.ownerTypeIs(OwnerType.Entity)) {
      value.firstName = '';
      value.lastName = ''
      value.isEntity = true;
    }
    return value;
  }

  get phoneValidationError(): string {
    if (fieldHasError(this.form, 'phoneNumber', 'required')) {
      return 'Enter phone number.'
    }
    if (fieldHasError(this.form, 'phoneNumber', 'minlength') || fieldHasError(this.form, 'phoneNumber', 'maxlength')) {
      return 'Phone number must be 10 digits.'
    }
    return '';
  }

  get tinValidationError(): string {
    if (fieldHasError(this.form, 'tinSuffix', 'minlength') || fieldHasError(this.form, 'tinSuffix', 'maxlength')) {
      return 'TIN must be 4 digits.'
    }
    return '';
  }
}
export class FormStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    return (control && control.invalid);
  }
}
