import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export type EventType = 'message' | '';

export class AppEvent<T> {
  constructor(public type: EventType, public payload: T) {}
}

@Injectable({
  providedIn: 'root'
})
export class EventBroadcastService {
  private eventBroker = new Subject<AppEvent<any>>();

  addEventListener = (eventType: EventType): Observable<AppEvent<any>> => this.eventBroker.pipe(filter(event => event.type === eventType));

  dispatch = <T>(event: AppEvent<T>): void => { this.eventBroker.next(event); }
}
