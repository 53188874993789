import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { State } from 'src/app/models/rma.model';
import { RmaService } from 'src/app/services/rma.service';
import { getCurrentReinsuranceYear } from 'src/app/utility';

@Component({
  selector: 'hh-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent implements OnInit {
  @Input() addressType: string = '';
  @Input() showSameAsCheckbox: boolean = false;
  @Input() sameAsAddressType: string = '';
  
  @Input() 
  set sameAsOther(value: boolean) {
    this._sameAsOther = value;
    if (value && this._otherAddress) {
      this.setFormValue(this._otherAddress);
    } else if (this._address) {
      this.setFormValue(this._address);
    }
  }
  @Input()
  set address(value: any) {
    if (value) {
      if (value.id) {
        delete value.id;
      }
      if (value.fullAddress) {
        delete value.fullAddress;
      }
      this._address = value;
      this.setFormValue(value);
    }
  }
  @Output() sameAsOtherChange = new EventEmitter<boolean>();

  @Input()
  set otherAddress(value: any) {
    if (value) {
      if (value.id) {
        delete value.id;
      }
      if (value.fullAddress) {
        delete value.fullAddress;
      }
      this._otherAddress = value;
      if (this._sameAsOther) {
        this.setFormValue(value);
      }
    }
  }
  @Output() addressChange = new EventEmitter<any>();

  states: State[] = [];
  _sameAsOther: boolean = false;
  form: FormGroup;
  _otherAddress: any;
  _address: any;
  formSubscription: Subscription;

  constructor(private builder: FormBuilder, private rmaService: RmaService) {
    this.form = this.builder.group({
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/\d*/)]]
    });

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      this.addressChange.emit(value);
    });
  }

  subscribeToFormChanges() {
    this.formSubscription = this.form.valueChanges.subscribe(value => {
      this.addressChange.emit(value);
    });
  }

  ngOnInit(): void {
    this.rmaService
      .getStates({reinsuranceYearEqualsFilter: getCurrentReinsuranceYear().toString()})
      .subscribe(res => this.states = res);
  }

  setFormValue(value: any) {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.form.setValue(value);
    this.subscribeToFormChanges();
  }

  markSameAsOther(event: MatCheckboxChange) {
    this.sameAsOtherChange.emit(event.checked);
    this.sameAsOther = event.checked;
  }

  markAsTouched() {
    this.form.markAllAsTouched();
  }

  get touched(): boolean {
    return this.form.touched;
  }

  get valid(): boolean {
    return this.form.valid;
  }
}
