<h2 mat-dialog-title>{{options.title}}</h2>
<mat-dialog-content>
  <div [innerHtml]="options.body"></div>
</mat-dialog-content>
<div class="dialog-actions">
  <mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-flat-button [color]="options.applyAlertStyle ? 'warn' :'primary'" (click)="confirm()" [ngClass]="{'dialog-confirm-button-alert': options.applyAlertStyle}">{{options.confirmText}}</button>
    <button mat-flat-button (click)="cancel()" class="cancel-btn">{{options.cancelText}}</button>
  </mat-dialog-actions>
</div>