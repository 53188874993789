import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MsalInterceptor, MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { MaterialModule } from 'src/app/material/material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DocumentsComponent } from 'src/app/documents/documents.component';
import { DocumentsListComponent } from 'src/app/documents/documents-list/documents-list.component';
import { ProcessDocumentComponent } from 'src/app/documents/process-document/process-document.component';
import { HarvestsComponent } from 'src/app/harvests/harvests.component';
import { UsersComponent } from 'src/app/users/users.component';
import { AgentAgencyComponent } from 'src/app/harvests/agent-agency/agent-agency.component';
import { DocumentPropertiesComponent } from 'src/app/documents/document-properties/document-properties.component';
import { HarvestOwnerDetailsComponent } from 'src/app/harvests/harvest-owner-details/harvest-owner-details.component';
import { AddressDetailsComponent } from 'src/app/harvests/address-details/address-details.component';
import { SBIsListComponent } from 'src/app/harvests/sbis-list/sbis-list.component';
import { AddDocumentComponent } from 'src/app/documents/add/add.component';
import { HarvestListComponent } from 'src/app/harvests/harvest-list/harvest-list.component';
import { UserHarvestsComponent } from 'src/app/users/user-details/user-harvests/user-harvests.component';
import { UserDocumentReviewsComponent } from 'src/app/users/user-details/user-document-reviews/user-document-reviews.component';
import { HomeComponent } from 'src/app/home/home.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1


import * as config from 'src/assets/configuration.json'
import { AgentSearchComponent } from 'src/app/documents/agent-search/agent-search.component';
import { HarvestOwnerSearchComponent } from 'src/app/documents/harvest-owner-search/harvest-owner-search.component';
import { MessagesComponent } from './messages/messages.component';
import { ValidationErrorComponent } from './messages/validation-error/validation-error.component';
import { PhonePipe } from './pipes/phone.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { DocumentTagsComponent } from './documents/document-tags/document-tags.component';
import { HarvestDetailsComponent } from './users/user-details/user-harvests/harvest-details/harvest-details.component';
import { PolicyInformationComponent } from './users/user-details/user-harvests/policy-information/policy-information.component';
import { ManageBoundariesComponent } from './harvests/manage-boundaries/manage-boundaries.component';
import { AddEditBoundaryComponent } from './harvests/manage-boundaries/add-edit-boundary/add-edit-boundary.component';
import { DataTableComponent } from './data-table/data-table.component';
import { YieldHistoryComponent } from './harvests/yield-history/yield-history.component';
import { HarvestBreadcrumbsComponent } from './harvests/harvest-breadcrumbs/harvest-breadcrumbs.component';
import { EditYieldHistoryComponent } from './harvests/yield-history/edit-yield-history/edit-yield-history.component';

@NgModule({
  declarations: [
    AppComponent,
    DocumentsComponent,
    DocumentsListComponent,
    DocumentTagsComponent,
    ProcessDocumentComponent,
    HarvestsComponent,
    UsersComponent,
    AgentAgencyComponent,
    DocumentPropertiesComponent,
    HarvestOwnerDetailsComponent,
    AddressDetailsComponent,
    SBIsListComponent,
    AddDocumentComponent,
    HarvestListComponent,
    UserHarvestsComponent,
    UserDocumentReviewsComponent,
    HomeComponent,
    AgentSearchComponent,
    HarvestOwnerSearchComponent,
    MessagesComponent,
    ValidationErrorComponent,
    PhonePipe,
    ConfirmationDialogComponent,
    UserListComponent,
    UserDetailsComponent,
    HarvestDetailsComponent,
    PolicyInformationComponent,
    ManageBoundariesComponent,
    AddEditBoundaryComponent,
    DataTableComponent,
    YieldHistoryComponent,
    HarvestBreadcrumbsComponent,
    EditYieldHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    MsalModule.forRoot(new PublicClientApplication({
      auth:{
        clientId: config.clientId,
        authority: config.authority,
        redirectUri: config.redirectUri
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['harvest-history-api/*', ['api://0de8730c-9196-44d5-808e-8d1f647349cc/webapp']],
        ['rma/*', ['api://d5c8dacc-9719-4968-93e5-c7075538e427/Client']],
        ['ge-api/*', ['api://0de8730c-9196-44d5-808e-8d1f647349cc/.default']],
        ['user-information/*', [config.authServiceScope]],
      ])
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
