<mat-expansion-panel [(expanded)]="expanded">
  <mat-expansion-panel-header>
    Policy ({{policy?.policyNumber}} - {{ policy?.aipRecord?.name }})
  </mat-expansion-panel-header>
  <hh-messages *ngIf="errorMessages.length > 0" [messageType]="messageType" [messages]="errorMessages"></hh-messages>
  <hh-agent-agency [selectedAgent]="policy?.agent"></hh-agent-agency>
  <mat-action-row>
    <button mat-stroked-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isSaving">Save Policy</button>
  </mat-action-row>
</mat-expansion-panel>
