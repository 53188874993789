import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { mergeMap, map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { HarvestDocument, UserDocument, GrowerDocument, DocumentTag } from 'src/app/models/grower-document.model';
import { ServiceType } from 'src/app/models/type.model';
import { AuthUser } from '../models/auth-user.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getDocumentFileTypes(): Observable<ServiceType[]> {
    return this.getMany<ServiceType>('harvest-history-api/documentfiletype');
  }

  getDocumentTypes(): Observable<ServiceType[]> {
    return this.getMany<ServiceType>('harvest-history-api/documenttype');
  }

  getDocumentsByStatus(statusIds: string[]): Observable<UserDocument[]> {
    let query: HttpParams = new HttpParams();
    statusIds.forEach(element => {
      query = query.append('documentStatus', element);
    });

    return this.getMany<HarvestDocument>(`harvest-history-api/documentreview/document`, query)
      .pipe(
        mergeMap((documents: HarvestDocument[]) => {
          if (!documents || documents.length == 0) {
            return [];
          }

          //let userIds = documents.map(doc => doc.userId);
          let userIds = documents.map(doc => doc.emailAddress);
          let uniqueValues = userIds.filter((n, i) => userIds.indexOf(n) === i);
          uniqueValues = uniqueValues.filter(function (x) {
            return x != null;
          });
          return forkJoin({documents: of(documents), users: this.post<AuthUser[]>('/user-information/information/emails', {ids: uniqueValues})});
        }),
        map((results: {documents: HarvestDocument[], users: AuthUser[]}) => {
          let documents = results.documents.map(doc => {
            const user = results.users.find(x => x.email == doc.emailAddress);
            //const user = results.users.find(x => x.id == doc.userId);
            let userDocument: UserDocument = {
              userId: doc.userId ?? "N/A",
              firstName: user?.firstName ?? '',
              lastName: user?.lastName ?? '',
              email: user?.email ?? '',
              fullName: user?.lastNameFirst ?? '',
              document: {
                id: doc.documentId,
                uploadDate: doc.uploadDate,
                url: 'harvest-history-api/document/' + doc.documentId + '/file',
                documentFileType: doc.documentFileType,
                documentStatusType: doc.documentStatusType,
                documentTags: doc.documentTags,
                policy: doc.policy,
                plantedYear: doc.plantedYear
              }
            }

            return userDocument;
          })

          return documents;
        })
      );
  }

  getUploadedDocuments(): Observable<UserDocument[]> {
    return this.getDocumentStatusTypes()
      .pipe(
        mergeMap(statuses => {
          const uploaded = statuses.find(x => x.type.toLowerCase() === 'uploaded') || null;
          if (uploaded === null) {
            return [];
          }

          let query: HttpParams = new HttpParams().append('documentStatus', uploaded!.id);
          return this.getMany<HarvestDocument>(`harvest-history-api/documentreview/document`, query);
        }),
        mergeMap((documents: HarvestDocument[]) => {
            if (!documents || documents.length == 0) {
              return [];
            }

            let userIds = documents.map(doc => doc.userId);
            let uniqueValues = userIds.filter((n, i) => userIds.indexOf(n) === i);
            uniqueValues = uniqueValues.filter(function (x) {
              return x != null;
            });
            return forkJoin({documents: of(documents), users: this.post<AuthUser[]>('/user-information/information/ids', {ids: uniqueValues})});
        }),
        map((results: {documents: HarvestDocument[], users: AuthUser[]}) => {
          let documents = results.documents.map(doc => {
            const user = results.users.find(x => x.id == doc.userId);
            let userDocument: UserDocument = {
              userId: doc.userId,
              firstName: user?.firstName ?? '',
              lastName: user?.lastName ?? '',
              email: user?.email ?? '',
              fullName: user?.lastNameFirst ?? '',
              document: {
                id: doc.documentId,
                uploadDate: doc.uploadDate,
                url: 'harvest-history-api/document/' + doc.documentId + '/file',
                documentFileType: doc.documentFileType,
                documentStatusType: doc.documentStatusType,
                documentTags: doc.documentTags,
                policy: doc.policy,
                plantedYear: doc.plantedYear
              }
            }

            return userDocument;
          })

          return documents;
        })
      );
  }

  getDocument(id: string): Observable<UserDocument> {
    return this.get<GrowerDocument>(`harvest-history-api/document/${id}`)
      .pipe(
        mergeMap((document: GrowerDocument) => {
          return forkJoin({document: of(document), user: this.getUserForDocument(document.id!)});
        }),
        mergeMap((results: {document: GrowerDocument, user: AuthUser}) => {
          let document: UserDocument = {
            userId: results.user.id,
            firstName: results.user.firstName,
            lastName: results.user.lastName,
            email: results.user.email,
            fullName: results.user.fullName,
            document: {
              id: results.document.id,
              uploadDate: results.document.uploadDate,
              url: 'harvest-history-api/document/' + results.document.id + '/file',
              documentFileType: results.document.documentFileType,
              documentStatusType: results.document.documentStatusType,
              documentTags: results.document.documentTags,
              policy: results.document.policy,
              plantedYear: results.document.plantedYear
            }
          };

          return of(document);
        })
      );
  }

  getUserForDocument(documentId: string): Observable<AuthUser> {
    return this.get<string>(`harvest-history-api/document/${documentId}/user`)
      .pipe(
        //mergeMap(userId => {
        mergeMap(emailAddress => {
          return this.get<AuthUser>(`/user-information/information/email/${emailAddress}`);
        })
      );
  }

  getDocumentFile(documentId: string): Observable<string> {
    return this.get<string>(`harvest-history-api/document/${documentId}/file`);
  }

  //uploadDocument(userId: string, file: File): Observable<GrowerDocument> {
  uploadDocument(userEmail: string, file: File): Observable<GrowerDocument> {
    //const uploadUrl: string = `harvest-history-api/user/${userId}/document`;
    const uploadUrl: string = `harvest-history-api/document?emailAddress=${userEmail}`;
    return this.post<GrowerDocument>(uploadUrl)?.pipe(
      mergeMap(document => {
        if (!document) {
          return of(document); //returns null without explicitly having to add it as a return type
        }

        const formData = new FormData();
        formData.append('file', file);

        return this.post<GrowerDocument>(`harvest-history-api/document/${document.id}/file`, formData);
      })
    );
  }

  rejectDocument(userId: string, documentId: string): Observable<GrowerDocument | null> {
    return forkJoin({statuses: this.getDocumentStatusTypes(), document: this.get<GrowerDocument>(`harvest-history-api/document/${documentId}`)})
      .pipe(
        mergeMap(result => {
          if (!result.statuses || result.statuses.length == 0 || !result.document) {
            return of(null);
          }

          const status = result.statuses.find(x => x.type == 'Rejected');
          let body = {
            plantedYear: null,
            documentStatusTypeId: status!.id,
            documentTypeId: null,
            policyId: null
          }
          //return this.put<GrowerDocument>(`harvest-history-api/user/${userId}/document/${documentId}`, body);
          return this.put<GrowerDocument>(`harvest-history-api/document/${documentId}`, body);
        })
      )
  }

  getDocumentTagTypes(): Observable<ServiceType[]> {
    return this.getMany<ServiceType>('harvest-history-api/documenttagtypes');
  }

  addDocumentTag(documentId: string, documentTagTypeId: string, createdBy: string): Observable<DocumentTag> {
    let body = {
      documentId: documentId,
      documentTagTypeId: documentTagTypeId,
      createdBy: createdBy
    }
    return this.post<DocumentTag>('harvest-history-api/documenttag', body);
  }

  deleteDocumentTag(tagId: string): Observable<boolean> {
    return this.delete<boolean>(`harvest-history-api/documenttag/${tagId}`);
  }

  getDocumentStatusTypes(): Observable<ServiceType[]> {
    return this.getMany(`harvest-history-api/documentstatustype`);
  }
}
