<hh-harvest-breadcrumbs></hh-harvest-breadcrumbs>
<hh-messages id="message_box" [messageType]="messageType" [title]="messageTitle" [messages]="messages"></hh-messages>
<div class="search-wrapper">
  <h3>Available Yield History by Unit</h3>
  <form class="flex full" (ngSubmit)="applyFilter()">
    <mat-form-field>
      <input name="searchbox" matInput placeholder="Search Units by..." type="text" [(ngModel)]="search">
    </mat-form-field>
    <button type="submit" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
  </form>
</div>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="basicUnitNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Basic Unit #</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.basicUnitNumber}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="optionalUnitNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Optional Unit #</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.optionalUnitNumber}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="unitStructure">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Unit Structure</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.unitStructureRecord?.code}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="practiceType">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Crop Practice</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.practiceType?.display}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="highRiskLand">
    <mat-header-cell *matHeaderCellDef mat-sort-header>High Risk Land</mat-header-cell>
    <mat-cell *matCellDef="let item">{{getHighRiskLandDisplay(item.highRiskLand)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <span class="align-right">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
      </span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item class="mat-menu-header">
          <p><strong>Available Actions</strong></p>
        </div>
        <a mat-menu-item (click)="editYieldHistory(item)">Edit Yield History</a>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="noData">
    <mat-footer-cell *matFooterCellDef>
      <span>{{noDataText}}</span>
    </mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
  <mat-row [class.selected]="row.id === unit?.id" *matRowDef="let row; columns: displayColumns"></mat-row>
  <mat-footer-row [class.hide]="!loading && dataSource.data.length > 0 && dataSource.filteredData.length > 0" *matFooterRowDef="['noData']"></mat-footer-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>

<hh-edit-yield-history id="yield_history_section" *ngIf="unit" [unit]="unit" (cancelled)="unit = undefined" (saved)="historySaved($event)"></hh-edit-yield-history>
