<h2>Grower Details - {{user?.lastNameFirst}}</h2>
<hh-messages id="error_box" *ngIf="messages.length > 0" [title]="messageTitle" messageType="alert" [messages]="messages"></hh-messages>
<mat-tab-group>
  <mat-tab label="Harvest Details">
    <ng-template mat-tab-label>Harvest Details</ng-template>
    <router-outlet name="harvests" (activate)="onHarvestOutletActivate($event)"></router-outlet>
  </mat-tab>
  <!-- <mat-tab label="Grower Documents">
    <ng-template mat-tab-label>Grower Documents</ng-template>
    <hh-user-document-reviews [user]="user"></hh-user-document-reviews>
  </mat-tab> -->
</mat-tab-group>
