
<div class="search-wrapper">
  <form class="flex full" (ngSubmit)="filterHarvests()">
    <mat-form-field>
      <input name="search" matInput placeholder="Search harvests..." [(ngModel)]="search">
    </mat-form-field>
    <button type="submit" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
  </form>
</div>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="state">
    <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.state}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="cropType">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Crop</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.commodityName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="harvestOwner">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Harvest Owner</mat-header-cell>
    <mat-cell *matCellDef="let item">{{getHarvestOwnerName(item.harvestOwner)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="align-right">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
      </span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item class="mat-menu-header">
          <p><strong>Available Actions</strong></p>
        </div>
        <a mat-menu-item (click)="manageHarvestDetails(element)"><i class="fas fa-edit"></i> Edit Harvest Details</a>
        <a mat-menu-item (click)="manageBoundaries(element)"><i class="fas fa-layer-plus"></i> Manage Boundaries</a>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="noData">
    <mat-footer-cell *matFooterCellDef>{{noDataText}}</mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['state', 'cropType', 'harvestOwner', 'actions']"></mat-header-row>
  <mat-row [class.selected]="row.id === harvest?.id" *matRowDef="let row; columns:['state', 'cropType', 'harvestOwner', 'actions']"></mat-row>
  <mat-footer-row [class.hide]="dataSource.filteredData.length > 0" *matFooterRowDef="['noData']"></mat-footer-row>
</mat-table>
<mat-paginator pageSize="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
<hh-harvest-details id="harvest_details_section" [harvest]="harvest" (savedHarvest)="harvestSaved($event)" *ngIf="harvest"></hh-harvest-details>
