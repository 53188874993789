<form class="flex full" [formGroup]="form">
  <div class="flex half vertical">
    <mat-radio-group aria-label="Select an option" (change)="changeOwnerType($event.value)" id="harvestOwnerTypeRadioGroup">
      <div class="column left">
        <mat-radio-button [checked]="ownerTypeIs(ownerTypes.Individual)" [value]="ownerTypes.Individual" color="primary" id="harvestOwnerTypeIndividual">Individual</mat-radio-button>
      </div>
      <div class="column right">
        <mat-radio-button [checked]="ownerTypeIs(ownerTypes.Entity)" [value]="ownerTypes.Entity" color="primary" id="harvestOwnerTypeEntity">Entity</mat-radio-button>
      </div>
    </mat-radio-group>
    <div class="flex" [class.hide]="!ownerTypeIs(ownerTypes.Individual)">
      <div class="column">
        <mat-form-field>
          <input matInput [errorStateMatcher] ="matcher" placeholder="First Name" required formControlName="firstName" id="harvestOwnerFirstName">
          <mat-error>
            <hh-validation-error message="Enter first name."></hh-validation-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field>
          <input matInput [errorStateMatcher] ="matcher" placeholder="Last Name" required formControlName="lastName" id="harvestOwnerLastName">
          <mat-error>
            <hh-validation-error message="Enter last name."></hh-validation-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex full" [class.hide]="!ownerTypeIs(ownerTypes.Entity)">
      <mat-form-field>
        <input matInput [errorStateMatcher] ="matcher" placeholder="Entity Name" required formControlName="entityName" id="harvestOwnerEntityName">
        <mat-error>
          <hh-validation-error message="Enter entity name."></hh-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field>
      <input matInput placeholder="Doing Business As" formControlName="doingBusinessAs" id="harvestOwnerDoingBusinessAs">
    </mat-form-field>
  </div>
  <div class="flex half vertical">
    <mat-form-field>
      <input matInput placeholder="Email Address" formControlName="emailAddress" id="harvestOwnerEmailAddress">
    </mat-form-field>
    <mat-form-field>
      <input matInput mask="(000) 000-0000" placeholder="Phone Number" required formControlName="phoneNumber" id="harvestOwnerPhoneNumber">
      <mat-error>
        <hh-validation-error [message]="phoneValidationError"></hh-validation-error>
      </mat-error>
    </mat-form-field>
    <div class="column">
      <mat-form-field>
        <input matInput mask="0000" placeholder="Last Four of TIN" formControlName="tinSuffix" id="harvestOwnerTIN">
        <mat-error>
          <hh-validation-error [message]="tinValidationError"></hh-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>