<form [formGroup]="form">
  <div class="arrowed-sub no-margin-bottom document-basics-container">
    <h3>Document Basics</h3>
    <div class="flex vertical">
      <div class="flex">
        <div class="column">
          <mat-form-field>
            <mat-select formControlName="year" required placeholder="Crop Year" (selectionChange)="onCropYearChange($event.value)">
              <mat-option *ngFor="let year of years" [value]="year.year">{{ year.year }}</mat-option>
            </mat-select>
            <mat-error>
              <hh-validation-error message="Select year."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field>
            <mat-select formControlName="documentType" required placeholder="Document Type">
              <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.id">{{ documentType.description }}</mat-option>
            </mat-select>
            <mat-error>
              <hh-validation-error message="Select document type."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="arrowed-sub no-margin-bottom policy-information-container">
    <h3>Policy Information</h3>
    <div class="flex vertical">
      <mat-form-field>
        <input matInput formControlName="policyNumber" placeholder="Policy Number" required>
        <mat-error>
          <hh-validation-error message="Enter policy number."></hh-validation-error>
        </mat-error>
      </mat-form-field>
      <div class="flex">
        <div class="column">
          <mat-form-field>
            <mat-select formControlName="state" required placeholder="State">
              <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
            </mat-select>
            <mat-error>
              <hh-validation-error message="Select state."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field class="cropTypesFields" formArrayName="cropTypes" *ngFor="let cropType of cropTypesForm.controls; let i = index">
            <mat-label>Commodity</mat-label>
            <input type="text"
              placeholder="Select A Commodity"
              aria-label="Commodity"
              matInput
              [matAutocomplete]="auto"
              [formControlName]="i"
              required>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="cropTypeDisplay" (optionSelected)='onOptionSelected()'>
              <mat-option *ngFor="let type of filteredCropTypes | async" [value]="type">
                {{type.display}}
              </mat-option>
            </mat-autocomplete>
            <mat-error>
              <hh-validation-error message="Select commodity."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="flex vertical">
      <mat-form-field>
        <mat-select formControlName="approvedInsuranceProvider" required placeholder="AIP/MGA">
          <mat-option *ngFor="let aip of aips" [value]="aip.id">{{ aip.display }}</mat-option>
        </mat-select>
        <mat-error>
          <hh-validation-error message="Select AIP/MGA."></hh-validation-error>
        </mat-error>
      </mat-form-field>
      <a mat-button color="primary" (click)="removeCropType()" *ngIf="cropTypesForm.controls.length > 1"><mat-icon style="padding-top: 5px; font-size: 1rem;">remove_circle</mat-icon> Remove Additional Commodity</a>
      <a mat-button color="primary" (click)="addCropType()"><mat-icon>add_circle</mat-icon> Include More Commodities</a>
    </div>
  </div>
</form>
