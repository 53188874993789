import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'src/app/services/document.service';
import { UserDocument, DocumentTag } from 'src/app/models/grower-document.model';
import { createMessageContent } from 'src/app/utility';
import { ServiceType } from 'src/app/models/type.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user-service.service';
import { ProfileType } from 'src/app/models/profile.model';
import { AuthUser } from 'src/app/models/auth-user.model';

@Component({
  selector: 'hh-document-tags',
  templateUrl: './document-tags.component.html',
  styleUrls: ['./document-tags.component.scss']
})
export class DocumentTagsComponent implements OnInit {
  user!: AuthUser;
  documentId: string = '';
  document!: UserDocument;
  documentTagTypes!: ServiceType[];
  documentTags!: DocumentTag[];
  form: FormGroup;
  successTitle = '';
  successText = '';
  errorTitle: string = '';
  errorMessages: string[] = [];
  loadingDocument: boolean = true;
  loadingDocumentTags: boolean = true;
  loadingDocumentTagTypes: boolean = true;
  processingTagOperation: boolean = false;
  tagOperationText: string = '';
  selectedTagTypeId: string = '';
  profile!: ProfileType;

  constructor(private builder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private documentService: DocumentService,
              private userService: UserService) {
    this.form = builder.group({
      documentTagTypeId: ['', Validators.required]
    });
    this.form.get("documentTagTypeId")?.valueChanges
      .subscribe(x => {
        this.setSelectedTagTypeId(x);
    });
  }

  ngOnInit(): void {
    this.userService.getProfile().subscribe(result => this.profile = result);
    this.subscribeToParams();
  }

  private setSelectedTagTypeId(id: string) {
    this.selectedTagTypeId = id;
  }

  subscribeToParams() {
    this.route.params.subscribe(params => {
      this.documentId = params['id'];
      this.getDocumentInformation();
    });
  }

  getDocumentInformation() {
    this.documentService.getUserForDocument(this.documentId)
    .subscribe(user => this.user = user,
      error => {
        this.loadingDocument = false;
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorMessages = [message?.messages[0] ?? ''];
        }
        else {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
      });
    this.documentService.getDocument(this.documentId)
    .subscribe(document => {
          this.document = document;
          this.documentTags = document.document.documentTags ?? [];
          this.getDocumentTagTypes();
        },
        error => {
          this.loadingDocument = false;
          if (error.status) {
            const message = createMessageContent(error.status);
            this.errorTitle = message?.title ?? '';
            this.errorMessages = [message?.messages[0] ?? ''];
          }
          else {
            this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
          }
        });
  }

  getDocumentTagTypes() {
    this.documentService.getDocumentTagTypes()
    .subscribe(documentTagTypesResult => {
        this.documentTagTypes = this.sanitizeDocumentTagTypes(this.document, documentTagTypesResult);
        this.form.controls["documentTagTypeId"].setValue(null);
        this.form.controls["documentTagTypeId"].markAsUntouched();
        this.loadingDocument = false;
        this.loadingDocumentTags = false;
        this.loadingDocumentTagTypes = false;
      },
      error => {
        this.loadingDocumentTagTypes = false;
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorMessages = [message?.messages[0] ?? ''];
        }
        else {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
      });
  }

  sanitizeDocumentTagTypes(document: UserDocument, documentTagTypes: ServiceType[]) {
    let sanitizedRecords: ServiceType[] = [];

    if(document && document.document.documentTags && document.document.documentTags.length > 0) {
      let tagTypeRecords: ServiceType[] = [];
      document.document.documentTags.forEach(element => {
        tagTypeRecords.push(element.documentTagType);
      });

      var results = documentTagTypes.filter(function(obj) {
        return !tagTypeRecords.some(function(obj2) {
            return obj.id == obj2.id;
        });
      });

      sanitizedRecords = (results && results.length > 0) ? results : [];
    }
    else {
      sanitizedRecords = documentTagTypes;
    }

    return sanitizedRecords;
  }

  addDocumentTag() {
    this.form.controls.documentTagTypeId.disable();
    this.processingTagOperation = true;
    this.tagOperationText = "Adding Tag..."

    if(this.selectedTagTypeId === null || this.selectedTagTypeId === undefined) {
      this.form.controls.documentTagTypeId.enable();
      this.processingTagOperation = false;
      this.tagOperationText = ""
      this.form.controls["documentTagTypeId"].markAllAsTouched();
    }
    else {
      this.documentService.addDocumentTag(this.documentId, this.selectedTagTypeId, this.profile.displayName ?? "UNKNOWN")
      .subscribe(result => {
          if(result) {
            this.successTitle = "Success";
            this.successText = "Successfully added new tag record.";
          }
          else {
            this.errorTitle = "Failure";
            this.errorMessages = ["Failed to add new tag record, please review logs or try again later."];
          }
          this.form.controls.documentTagTypeId.enable();
          this.processingTagOperation = false;
          this.loadingDocumentTags = true;
          this.getDocumentInformation();
        },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.errorTitle = message?.title ?? '';
            this.errorMessages = [message?.messages[0] ?? ''];
          }
          else {
            this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
          }
          this.form.controls.documentTagTypeId.enable();
          this.processingTagOperation = false;
        });
    }
  }

  deleteDocumentTag(id: string) {
    this.form.controls.documentTagTypeId.disable();
    this.processingTagOperation = true;
    this.tagOperationText = "Removing Tag...";

    this.documentService.deleteDocumentTag(id)
    .subscribe(result => {
        if(result) {
          this.successTitle = "Success";
          this.successText = "Successfully delete tag record.";
        }
        else {
          this.errorTitle = "Failure";
          this.errorMessages = ["Failed to delete tag record, please review logs or try again later."];
        }
        this.form.controls.documentTagTypeId.enable();
        this.processingTagOperation = false;
        this.loadingDocumentTags = true;
        this.getDocumentInformation();
      },
      error => {
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorMessages = [message?.messages[0] ?? ''];
        }
        else {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
        this.form.controls.documentTagTypeId.enable();
        this.processingTagOperation = false;
      });
  }

  backToList() {
    this.router.navigate(['documents']);
  }
}
