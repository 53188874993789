<h2>Harvests Available for Intake</h2>
<div class="search-wrapper">
  <div class="search-component-container">
    <form class="flex" (ngSubmit)="applyFilter()">
        <mat-form-field>
          <input name="searchbox" matInput placeholder="Search harvests..." type="text" [(ngModel)]="search">
        </mat-form-field>
        <button type="submit" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
    </form>
  </div>
  <div class="filter-component-container">
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="reviewStatusTypesControl" (selectionChange)="setReviewStatusFilters($event)" multiple>
        <mat-option *ngFor="let record of reviewStatusTypes" [value]="record.id">{{record.description}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<hh-messages id="error_box" *ngIf="errorText !== ''" [title]="errorTitle" [messages]="[errorText]" [messageType]="messageType"></hh-messages>
<hh-messages id="success_box" *ngIf="showSuccess" [messages]="[successText]" [messageType]="successMessageType"></hh-messages>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="userName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.lastName}}, {{element.firstName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="state">
    <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.stateName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="cropType">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Crop</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.commodityName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.status}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="align-right">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
      </span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item class="mat-menu-header">
          <p><strong>Available Actions</strong></p>
        </div>
        <a mat-menu-item *ngFor="let document of element.documents" (click)="openDocumentFile(document.masterDocumentId)"><mat-icon color="primary">picture_as_pdf</mat-icon> View Document {{document.plantedYear}} - ({{document.documentType.description}})</a>

        <a mat-menu-item (click)="continueEdit(element.harvest.id, element.harvest.harvestOwnerId, element.email)"><i class="fas fa-edit"></i> Edit Harvest Details</a>

        <div *ngIf="element.canStart">
        <a mat-menu-item (click)="updateReview(element.id, element.email)"><i class="fas fa-tasks"></i><mat-icon color="primary">edit_note</mat-icon> Start Intake</a>
        </div>

        <div *ngIf="element.canContinue">
          <a mat-menu-item (click)="continueReview(element.id, element.harvest.id, element.harvest.harvestOwnerId, element.email)"><mat-icon color="primary">create</mat-icon> Continue Intake</a>
        </div>

        <div *ngIf="element.canComplete">
          <a mat-menu-item (click)="completeReview(element)"><mat-icon color="primary">done_outline</mat-icon> Complete Intake</a>
        </div>

        <div *ngIf="element.canAbandon">
          <a mat-menu-item (click)="abandonReview(element)"><mat-icon color="primary">delete</mat-icon> Abandon</a>
        </div>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="noData">
    <mat-footer-cell *matFooterCellDef>
      <span *ngIf="loading">Loading...</span>
    </mat-footer-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="['userName', 'email', 'state','cropType', 'status', 'actions']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['userName', 'email','state','cropType', 'status', 'actions']"></mat-row>
  <mat-footer-row *matFooterRowDef="['noData']" [class.hide]="!loading"></mat-footer-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
