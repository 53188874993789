import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

interface ResponseEntryMap { [index: number] : { response: HttpResponse<any> | HttpErrorResponse, messageText: string }};

export const HttpResponseMessageMap: ResponseEntryMap = {
  200: { 
    response: new HttpResponse<any>({
      status: 200,
      statusText: 'Ok'
    }),
    messageText: '' 
  },
  201: { 
    response: new HttpResponse<any>({
      status: 201,
      statusText: 'Created'
    }),
    messageText: '' 
  },
  202: { 
    response: new HttpResponse<any>({
      status: 202,
      statusText: 'Accepted'
    }),
    messageText: '' 
  },
  204: { 
    response: new HttpResponse<any>({
      status: 204,
      statusText: 'No Content'
    }),
    messageText: '' 
  },
  400: { 
    response: new HttpErrorResponse({
      error: '400 error',
      status: 400,
      statusText: 'Bad Request'
    }),
    messageText: 'Your browser is not responding properly. Check your path and try again.' 
  },
  401: { 
    response: new HttpErrorResponse({
      error: '401 error',
      status: 401,
      statusText: 'Unauthorized'
    }),
    messageText: 'This page is off limits to unauthorized users.' 
  },
  403: { 
    response: new HttpErrorResponse({
      error: '403 error',
      status: 403,
      statusText: 'Forbidden'
    }),
    messageText: 'This directory is off limits to unauthorized users.' 
  },
  404: { 
    response: new HttpErrorResponse({
      error: '404 error',
      status: 404,
      statusText: 'Not Found'
    }),
    messageText: 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. Try refreshing this page, or navigate to the home page.' 
  },
  408: { 
    response: new HttpErrorResponse({
      error: '408 error',
      status: 408,
      statusText: 'Request Timeout'
    }),
    messageText: 'Your session has timed out due to inactivity. Please Log In again.' 
  },
  409: { 
    response: new HttpErrorResponse({
      error: '409 error',
      status: 409,
      statusText: 'Conflict'
    }),
    messageText: 'The server took too long to display the page, or there were too many requests for this page at the same time. Try refreshing this page, or navigate to the home page.' 
  },
  412: { 
    response: new HttpErrorResponse({
      error: '412 error',
      status: 412,
      statusText: 'Precondition Failed'
    }),
    messageText: 'The URL request has failed. Try refreshing this page, or navigate to the home page.' 
  },
  422: { 
    response: new HttpErrorResponse({
      error: '422 error',
      status: 422,
      statusText: 'Unproccessable Entity'
    }),
    messageText: 'Your browser is not responding properly. Check your path and try again.' 
  },
  429: { 
    response: new HttpErrorResponse({
      error: '429 error',
      status: 429,
      statusText: 'Too Many Requests'
    }),
    messageText: 'We are sorry, but you have sent too many requests in a given amout of time. Please try again later.' 
  },
  500: { 
    response: new HttpErrorResponse({
      error: '500 error',
      status: 500,
      statusText: 'Internal Server Error'
    }),
    messageText: 'An error has occurred. Try refreshing this page, or navigate to the home page' 
  },
  503: { 
    response: new HttpErrorResponse({
      error: '503 error',
      status: 503,
      statusText: 'Service Unavailable'
    }),
    messageText: 'The server is currently unavailable. Try refreshing this page, or navigate to the home page.' 
  },
  504: { 
    response: new HttpErrorResponse({
      error: '504 error',
      status: 504,
      statusText: 'Gateway Timeout'
    }),
    messageText: 'The server was awaiting a response from another server and has timed out. Try refreshing this page or navigate to the home page.' 
  }
};