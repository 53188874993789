<header class="no-padding-left no-padding-right">
  <h2 class="no-margin-bottom">Document Management</h2>
  <div class="spacer"></div>
  <button (click)="addDocument()" mat-raised-button color="primary"><mat-icon>add</mat-icon> Add Document</button>
</header>
<div class="search-wrapper">
  <div class="search-component-container">
    <form class="flex" (ngSubmit)="filterDocuments()">
      <mat-form-field>
        <input name="search" matInput placeholder="Search documents..." [(ngModel)]="search">
      </mat-form-field>
      <button type="submit" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
    </form>
  </div>
  <div class="filter-component-container">
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="documentStatusTypesControl" (selectionChange)="setDocumentStatusFilters($event)" multiple>
        <mat-option *ngFor="let record of documentStatusTypes" [value]="record.id">{{record.description}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<hh-messages id="error_box" *ngIf="errorMessages.length > 0" [title]="errorTitle" messageType="alert" [messages]="errorMessages"></hh-messages>
<hh-messages id = "success_box" *ngIf="successText !== ''" [title]="successTitle" [messages]="[successText]"></hh-messages>

<mat-table [dataSource]="dataSource" matSort active="uploadDate" direction="desc">
  <ng-container matColumnDef="fullName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.fullName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.email}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="uploadDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Upload Date</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.document.uploadDate | date:'MM/dd/YYYY'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="tags">
    <mat-header-cell class="tags-width" *matHeaderCellDef><span class="tags-span">Tags</span></mat-header-cell>
    <mat-cell *matCellDef="let item" title="{{processTagTitle(item.document.documentTags)}}">
      <div class="container-tags-tooltip" matTooltip="Testing Tooltip">
        <span class="tags-span">
          <span class="tag-record" *ngFor="let func of item.document.documentTags">{{processTagDisplay(func, item.document.documentTags)}}</span>
        </span>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="documentStatus">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let item">{{item.document.documentStatusType.description}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <span class="align-right">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
      </span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item class="mat-menu-header">
          <p><strong>Available Actions</strong></p>
        </div>
        <a mat-menu-item (click)="openDocumentFile(item.document.id)"><mat-icon color="primary">picture_as_pdf</mat-icon> View Document</a>
        <ng-container *ngIf="allowDocumentProcess(item.document.documentStatusType.type)">
          <a mat-menu-item (click)="processDocument(item.document.id)"><mat-icon color="primary">edit_note</mat-icon> Process Document</a>
        </ng-container>
        <a mat-menu-item (click)="manageDocumentTags(item.document.id)"><mat-icon color="primary">sell</mat-icon> Manage Tags</a>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="noData">
    <mat-footer-cell *matFooterCellDef>
      <span *ngIf="loading">Loading...</span>
    </mat-footer-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="['fullName', 'email', 'uploadDate', 'tags', 'documentStatus', 'actions']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['fullName', 'email', 'uploadDate', 'tags', 'documentStatus', 'actions']" (click)="highlightRow(row)" [ngClass]="{'highlight-mat-table-row' : row.document.id == getSelectedRowId()}"></mat-row>
  <mat-footer-row *matFooterRowDef="['noData']" [class.hide]="!loading"></mat-footer-row>
</mat-table>
<mat-paginator pageSize="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
