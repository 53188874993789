<header>
  <h2 class="no-margin no-padding">Add Document</h2>
  <div class="spacer"></div>
  <a mat-button color="primary" [routerLink]="['']"><mat-icon>navigate_before</mat-icon> Back to Manage Documents</a>
</header>
<hh-messages id="messages_box" *ngIf="searched || saved || errored" [title]="messageTitle" [messageType]="messageType" [messages]="messages"></hh-messages>
<header class="small-toolbar">
  <h3 class="no-margin">Find Grower and Upload Document</h3>
</header>
<section class class="sub-container">
  <form (ngSubmit)="search()" class="flex full" [formGroup]="searchForm">
    <div class="column">
      <mat-form-field>
        <input matInput formControlName="email" placeholder="Find Grower by Email Address" [(ngModel)]="userEmail">
        <mat-error>
          <hh-validation-error [message]="emailErrorMessage"></hh-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="column">
      <button type="submit" mat-stroked-button [disabled]="searching"><mat-icon>search</mat-icon> Search</button>
    </div>
  </form>
  <div *ngIf="showForm" class="flex full">
    <div class="flex half">
      <div class="column">
        <mat-form-field>
          <input matInput [disabled]="showUpload" placeholder="First Name" [(ngModel)]="firstName">
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field>
          <input matInput [disabled]="showUpload" placeholder="Last Name" [(ngModel)]="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="column" *ngIf="showAddUser">
      <button mat-flat-button color="primary" (click)="addUser()">Add User</button>
    </div>
    <form class="column" [formGroup]="fileForm" *ngIf="showUpload">
      <input id="files" formControlName="fileInput" #file type="file" (change)="fileChanged($event)">
      <em class="sub-text">(Supported file format: {{ fileFormats }})</em>
    </form>
  </div>
</section>
<div class="button-wrapper">
  <button mat-flat-button color="primary" (click)="upload()" [disabled]="saving || userNotFound">Upload Documents</button>
  <button mat-stroked-button  [routerLink]="['']">Cancel</button>
</div>
