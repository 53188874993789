import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Configuration properties for a ConfigurationDialogComponent
 */
export class ConfirmationDialogOptions {
  /**
   * Instantiates a new ConfirmationDialogOptions
   * @param title Text to display in header of dialog
   * @param body Content of the dialog
   * @param cancelText Text to display in cancel button
   * @param confirmText Text to display in confirm button
   * @param applyAlertStyle Make the confirm button an alert button if true. Else, confirm button is primary button
   */
  constructor(
    public title: string,
    public body: string,
    public cancelText: string = 'No',
    public confirmText: string = 'Yes',
    public applyAlertStyle: boolean = false
  ) { }
}

@Component({
  selector: 'hh-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  options: ConfirmationDialogOptions;
  
  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: ConfirmationDialogOptions,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.options = data;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
