import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function uniqueRowValidator(controlName: string) : ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormArray) {
      let values = new Set((control.value as any[]).filter(v => v[controlName] !== '').map(v => v[controlName]));
      let groups = (control.controls as FormGroup[]);
      for (let value of values) {
        const dupeGroup = groups.filter(g => g.get(controlName)?.value == value);
        if (dupeGroup.length > 1) {
          return { 'unique': { controlName, formGroups: dupeGroup} };
        }
      }

    }
    return null;
  }
}