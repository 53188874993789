import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthUser } from 'src/app/models/auth-user.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user-service.service';
import { createMessageContent } from 'src/app/utility';

@Component({
  selector: 'hh-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  search:string = '';
  loading: boolean = false;
  isUserListError = false;
  userListErrorTitle = '';
  userListError:string[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(private userService: UserService) { }
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>([]);

  ngOnInit(): void {
    this.subscribeToParams();
  }

  subscribeToParams() {
    this.loading = true;
    this.userService.getUsersWithHarvests()
    .subscribe(systemUsers => {
      const users:User[] = systemUsers.map(s => ({
        email: s.email ?? '',
        firstName: s.firstName ?? '',
        lastName: s.lastName ?? '',
        phone: this.getPhone(s),
        userId:s.id ?? '',
      }));

      this.dataSource = new MatTableDataSource(users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: User, property: string) => {
        switch(property) {
          case 'userName':
            return item.lastName + ', ' + item.firstName;
          default: return (item as any)[property];
        }
      }
      this.loading = false;
      this.isUserListError = false;
    },error =>{
      this.isUserListError = true;
      if (error.status) {
        const message = createMessageContent(error.status);
        this.userListErrorTitle = message?.title ?? '';
        this.userListError = message?.messages ?? [];
      }
      else {
        this.userListErrorTitle = 'Error';
        this.userListError = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
      }

    })
  }

  applyFilter(){
    this.dataSource.filter = this.search?.trim().toLocaleLowerCase() ?? '';
  }

  getPhone(user: AuthUser) {
    if(user.businessPhone && user.businessPhone !== '') {
      return user.businessPhone;
    }
    else if(user.cellPhone && user.cellPhone !== '') {
      return user.cellPhone
    }
    else {
      return "N/A";
    }
  }

}
