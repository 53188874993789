<br /><br />
<section class="sub-container no-border no-padding">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        Harvest Owner Details
      </mat-expansion-panel-header>
      <hh-messages [messageType]="harvestDetailsMessageType" [title]="harvestDetailsMessageTitle" [messages]="harvestDetailsMessages"></hh-messages>
      <hh-harvest-owner-details [harvestOwner]="harvestOwner" (harvestOwnerTypeChangeWithFormData)="setOwnerTypeAndData($event)" (harvestOwnerTypeChange)="setOwnerType($event)" (ownerAsSBI)="updateFirstSBI($event)"></hh-harvest-owner-details>
      <section *ngIf="validHarvestOwner">
        <div class="arrowed-sub no-margin-bottom">
          <hh-address-details
            addressType="Physical"
            [address]="harvestOwner?.physicalAddress"
            (addressChange)="physicalAddressChange($event)"
          ></hh-address-details>
        </div>
        <div class="arrowed-sub no-margin-bottom">
          <hh-address-details
            addressType="Mailing"
            sameAsAddressType="Physical"
            [showSameAsCheckbox]="true"
            [address]="harvestOwner?.mailingAddress"
            [otherAddress]="harvestOwner?.physicalAddress"
            [sameAsOther]="sameAsPhysical"
            (addressChange)="mailingAddressChange($event)"
          ></hh-address-details>
        </div>
      </section>
      <div class="arrowed-sub no-margin-bottom">
        <h3>SBI Information</h3>
        <hh-sbis-list [existing]="true" [sbis]="sbis" [firstRowReadonly]="true"></hh-sbis-list>
      </div>
      <mat-action-row>
        <button mat-raised-button (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="isSaveDisabled" (click)="saveHarvestOwner()"><mat-icon>download</mat-icon> Save Harvest Owner</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <section class="sub-container margin-top no-padding-left no-padding-bottom no-padding-right">
    <h2>Source of the Harvest Data - Crop Insurance</h2>
    <hh-messages *ngIf="errorMessages.length > 0" messageType="alert" [messages]="errorMessages"></hh-messages>
    <mat-accordion>
      <hh-policy-information [policy]="policy" *ngFor="let policy of policies"></hh-policy-information>
    </mat-accordion>
    <h3 *ngIf="isFetchingPolicies"><i class="fas fa-spinner fa-pulse" *ngIf="isFetchingPolicies"></i> Loading...</h3>
  </section>
</section>
