import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { Unit, Yield } from 'src/app/models/boundary.model';
import { PracticeSearchParameters, RmaService, UnitStructureSearchParameters } from './rma.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends BaseService {

  constructor(http: HttpClient, private rmaService: RmaService) {
    super(http);
  }

  getUnitsForBoundary(boundaryId: string, includeRecords: boolean = false, practiceParams?: PracticeSearchParameters, unitParams?: UnitStructureSearchParameters): Observable<Unit[]> {
    if(includeRecords) {
      return forkJoin({
        units: this.getMany<Unit>(`harvest-history-api/documentreview/coverage/${boundaryId}/unit`),
        practices: this.rmaService.getPractices(practiceParams),
        structures: this.rmaService.getUnitStructures(unitParams)
      }).pipe(map(result => {
        result.units.map(x => {
          x.unitStructureRecord = result.structures.find(m => m.id === x.unitStructure);
          x.practiceType = result.practices.find(m => m.id == x.practiceTypeId);
        });

        return result.units;
      }));
    }
    else {
      return this.getMany<Unit>(`harvest-history-api/documentreview/coverage/${boundaryId}/unit`);
    }
  }

  getYieldHistoryForUnit(unitId: string): Observable<Yield[]> {
    return this.getMany<Yield>(`harvest-history-api/documentreview/unit/${unitId}/yieldhistory`);
  }

  updateYieldHistoryForUnit(unitId: string, yields: Yield[]): Observable<Yield[]> {
    return this.putMany<Yield>(`harvest-history-api/documentreview/unit/${unitId}/yieldhistory`, yields);
  }
}
