import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Agent } from 'src/app/models/agency.model';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { AgentAgencyComponent } from 'src/app/harvests/agent-agency/agent-agency.component';
import { Policy } from 'src/app/models/agency.model';
import { Guid } from 'src/app/models/guid';
import { AgencyAgentService } from 'src/app/services/agency-agent.service';
import { MessageType } from 'src/app/messages/messages.component';

@Component({
  selector: 'hh-policy-information',
  templateUrl: './policy-information.component.html',
  styleUrls: ['./policy-information.component.scss']
})
export class PolicyInformationComponent implements OnInit {
  @Input() policy!: Policy;
  @ViewChild(AgentAgencyComponent) agentAgency?: AgentAgencyComponent;

  @Input() expanded: boolean = false;
  errorMessages: string[] = [];

  messageType: MessageType = 'alert';
  isSaving: boolean = false;

  constructor(private dialog: DialogService, private agentAgencyService: AgencyAgentService) { }

  ngOnInit(): void {
  }

  get touched(): boolean {
    return this.agentAgency?.touched!;
  }

  reset(): void {
    const agent = {...this.policy.agent};

    window.setTimeout(() => {
      this.expanded = false;
      this.agentAgency?.markAsUntouched();
      this.errorMessages = [];
      this.policy.agent = agent;
    }, 0)
  }

  cancel() {
    if (this.agentAgency?.touched) {
      const agent = {...this.policy.agent};
      this.dialog.openUnsavedChangesDialog()
        .subscribe(shouldCancel => {
          this.expanded = !shouldCancel;
          
          if (shouldCancel) {
            this.agentAgency?.markAsUntouched();
            this.errorMessages = [];
            this.policy.agent = agent;
          }
        });
    }
    else {
      this.expanded = false;
    }
  }

  save() {
    this.errorMessages = [];

    // check validation
    this.agentAgency?.markAsTouched();
    if (!this.agentAgency?.valid) {
      this.messageType = 'alert';
      this.errorMessages = ['Enter required fields.'];
      return;
    }

    //save agent/agency info
    var agentRequest = this.agentAgency?.value!;
    if (agentRequest != null) {
      if (agentRequest.id && agentRequest.id != Guid.EMPTY) {
        this.isSaving = true;
        this.agentAgencyService.updateAgencyAgent(agentRequest)
          .subscribe(this.onAgentAgencyCallback.bind(this),
          _ => {
            this.isSaving = false;
            this.messageType = 'alert';
            this.errorMessages = ['Error occurred while attempting to save.'];
          });
      }
    }
  }

  private onAgentAgencyCallback(result: Agent | null) {
    this.agentAgency?.markAsUntouched();
    this.policy.agent = result!;
    this.isSaving = false;
    this.messageType = 'success';
    this.errorMessages = ['Agent/agency saved successfully'];
  }
}
