<mat-toolbar color="primary">
  <mat-toolbar-row>
    <img src="../assets/ge-logo-white.png" height="80%">
    <h1>&nbsp;&nbsp;Harvest History</h1>
    <div class="spacer"></div>
    <span class="welcome-msg"><strong>Welcome,</strong> {{profile?.displayName}}</span>
    <button mat-button *ngIf="!loginDisplay" (click)="login()"><mat-icon>login</mat-icon>Login</button>
    <button mat-button *ngIf="loginDisplay" (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
  </mat-toolbar-row>
  <mat-toolbar-row class="no-padding">
    <button *ngIf="loginDisplay" mat-button [routerLink]="['documents']"><mat-icon aria-hidden="true">feed</mat-icon> Manage Documents</button>
    <button *ngIf="loginDisplay" mat-button [routerLink]="['harvests']"><mat-icon aria-hidden="true">fact_check</mat-icon> Harvest Intake</button>
    <button *ngIf="loginDisplay" mat-button [routerLink]="['users']"><mat-icon aria-hidden="true">people</mat-icon> Manage User Harvests</button>
  </mat-toolbar-row>
</mat-toolbar>  
<section class="container">
  <section class="form-wrapper">
    <router-outlet *ngIf=!isIFrame></router-outlet>
  </section>
</section>
