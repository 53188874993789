import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentsListComponent } from './documents/documents-list/documents-list.component';
import { DocumentTagsComponent } from './documents/document-tags/document-tags.component';
import { ProcessDocumentComponent } from './documents/process-document/process-document.component';
import { HarvestsComponent } from './harvests/harvests.component';
import { UsersComponent } from './users/users.component';
import { AddDocumentComponent } from './documents/add/add.component';
import { HarvestListComponent } from './harvests/harvest-list/harvest-list.component';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { UserHarvestsComponent } from './users/user-details/user-harvests/user-harvests.component';
import { ManageBoundariesComponent } from './harvests/manage-boundaries/manage-boundaries.component';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { YieldHistoryComponent } from './harvests/yield-history/yield-history.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [MsalGuard],
        children: [
          { path: 'process/:id', component: ProcessDocumentComponent},
          { path: 'add', component: AddDocumentComponent },
          { path: 'tags/:id', component: DocumentTagsComponent },
          { path: '', pathMatch: 'full', component: DocumentsListComponent },
        ]
      },
      {
        path: 'harvests',
        component: HarvestsComponent,
        canActivate: [MsalGuard],
        children: [
          {path: '', pathMatch: 'full', component: HarvestListComponent }
        ]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: ':id',
            component: UserDetailsComponent,
            children: [
              {path: 'harvest/:harvestId/boundary/:boundaryId/yields', canDeactivate: [UnsavedChangesGuard], component: YieldHistoryComponent, outlet: 'harvests'},
              {path: 'harvest/:harvestId/boundaries', canDeactivate: [UnsavedChangesGuard], component: ManageBoundariesComponent, outlet: 'harvests'},
              {path: '', pathMatch: 'full', canDeactivate: [UnsavedChangesGuard], component: UserHarvestsComponent, outlet: 'harvests'}
            ]
          },
          { path: '', pathMatch: 'full', component: UserListComponent}
        ]
      },
      { path: '', pathMatch: 'full', redirectTo: 'documents' }
    ]
  }
];

const isIFrame = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIFrame ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
