<hh-harvest-breadcrumbs></hh-harvest-breadcrumbs>
<hh-messages id="messages_box" [messageType]="messageType" [title]="messageTitle" [messages]="messages"></hh-messages>
<div class="search-wrapper">
  <form class="flex full" (ngSubmit)="applyFilter()">
    <mat-form-field>
      <input name="searchbox" matInput placeholder="Search harvests..." type="text" [(ngModel)]="search">
    </mat-form-field>
    <button type="submit" mat-mini-fab color="primary"><mat-icon>search</mat-icon></button>
    <span style="margin-left:0.5rem;">
      <button mat-raised-button color="primary" (click)="manageBoundary(null)"><mat-icon>add</mat-icon>Add Boundary</button>
    </span>
  </form>
</div>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="county">
    <mat-header-cell *matHeaderCellDef mat-sort-header>County</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.countyRecord?.name}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="align-right">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
      </span>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div mat-menu-item class="mat-menu-header">
          <p><strong>Available Actions</strong></p>
        </div>
        <a mat-menu-item (click)="manageBoundary(element)"><i class="fas fa-edit"></i> Edit Boundary</a>
        <a mat-menu-item (click)="goToYieldHistory(element)"><i class="fas fa-layer-plus"></i> Manage Yield History</a>
        <a mat-menu-item (click)="deleteBoundary(element.id)"><mat-icon color="primary">delete</mat-icon> Delete Boundary</a>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="noData">
    <mat-footer-cell *matFooterCellDef>
      <span>{{noDataText}}</span>
    </mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['county', 'actions']"></mat-header-row>
  <mat-row [class.selected]="row.id == boundary?.id" *matRowDef="let row; columns:['county', 'actions']"></mat-row>
  <mat-footer-row [class.hide]="!loading && dataSource.data.length > 0 && dataSource.filteredData.length > 0" *matFooterRowDef="['noData']"></mat-footer-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>

<hh-add-edit-boundary id="add_edit_section"
  *ngIf="boundary"
  [harvestId]="harvestId"
  [counties]="counties"
  [year]="year"
  [harvest]="harvest"
  [boundaryId]="boundary?.id"
  (canceled)="cancelEdit()"
  (saved)="saved($event)"></hh-add-edit-boundary>
