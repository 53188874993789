import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AIP, CoverageLevel, CropType, Practice, InsurancePlan, County, State, UnitStructure, ReinsuranceYear } from 'src/app/models/rma.model';
import { BaseService } from 'src/app/services/base.service';
import { map } from 'rxjs/operators';

export interface CropTypeSearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  commodityNameContainsFilter?: string;
  commodityCodeEqualsFilter?: string;
}

export interface PracticeSearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  practiceNameContainsFilter?: string;
  practiceCodeEqualsFilter?: string;
  commodityCodeEqualsFilter?: string;
}

export interface InsurancePlanSearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  insurancePlanNameContainsFilter?: string;
  insurancePlanCodeEqualsFilter?: string;
}

export interface CountySearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  countyNameContainsFilter?: string;
  countyCodeEqualsFilter?: string;
  stateCodeEqualsFilter?: string;
}

export interface StateSearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  stateNameContainsFilter?: string;
  stateCodeEqualsFilter?: string;
}

export interface AIPSearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  aipNameContainsFilter?: string;
  aipCodeEqualsFilter?: string;
}

export interface UnitStructureSearchParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  unitStructureDescriptionContainsFilter?: string;
  unitStructureCodeEqualsFilter?: string;
}

export interface CoverageLevelParameters extends Record<string, string | undefined> {
  reinsuranceYearEqualsFilter?: string;
  coverageTypeCodeEqualsFilter?: string;
  insurancePlanCodeEqualsFilter?: string;
  commodityCodeEqualsFilter?: string;
  commodityYearEqualsFilter?: string;
  stateCodeEqualsFilter?: string;
}

export interface ReinsuranceYearSearchParamaters extends Record<string, string | undefined> {
  reinsuranceYearIsActiveFilter? : string;
}

@Injectable({
  providedIn: 'root'
})

export class RmaService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAIPs(params: AIPSearchParameters = {}): Observable<AIP[]> {

    return this
      .get<AIP[]>('/rma/aip', createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new AIP(x.id, x.name, x.code))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getCropTypeById(id: string): Observable<CropType> {
    return this.get<CropType>(`/rma/commodity/${id}`);
  }

  getStateById(id: string): Observable<State> {
    return this.get<State>(`/rma/state/${id}`);
  }

  getCountyById(id: string): Observable<County> {
    return this.get<County>(`/rma/county/${id}`);
  }

  getCropTypes(params: CropTypeSearchParameters = {}): Observable<CropType[]> {
    return this
      .get<CropType[]>('/rma/commodity', createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new CropType(x.id, x.name, x.code, x.abbreviation, x.commodityYear))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getPractices(params: PracticeSearchParameters = {}): Observable<Practice[]> {
    return this
      .get<Practice[]>('/rma/practice', createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new Practice(x.id, x.name, x.code, x.abbreviation, x.commodityCode))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getInsurancePlans(params: InsurancePlanSearchParameters = {}): Observable<InsurancePlan[]> {
    return this
      .get<InsurancePlan[]>('/rma/insuranceplan', createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new InsurancePlan(x.id, x.name, x.code, x.abbreviation))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getCoverageLevels(params: CoverageLevelParameters = {}): Observable<CoverageLevel[]> {
    return this
      .get<CoverageLevel[]>('/rma/priceelectionpercent', createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new CoverageLevel(x.reinsuranceYear, x.coverageLevelPercent, x.coverageTypeCode, x.insurancePlanCode))
                .sort(
                  (a, b) => {
                    if (a.coverageLevelPercent > b.coverageLevelPercent) return 1;
                    if (b.coverageLevelPercent > a.coverageLevelPercent) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getUnitStructures(params: UnitStructureSearchParameters = {}): Observable<UnitStructure[]> {
    return this
      .get<UnitStructure[]>(`/rma/unitstructure`, createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new UnitStructure(x.id, x.name, x.code))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getStates(params: StateSearchParameters = {}): Observable<State[]> {
    return this
      .get<State[]>(`/rma/state`, createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new State(x.id, x.name, x.code, x.abbreviation))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getCounties(params: CountySearchParameters = {}): Observable<County[]> {
    return this
      .get<County[]>('/rma/county', createHttpParams(params))
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map(x => new County(x.id, x.name, x.code, x.stateCode))
                .sort(
                  (a, b) => {
                    if (a.name > b.name) return 1;
                    if (b.name > a.name) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }

  getReinsuranceYears( params : ReinsuranceYearSearchParamaters = {} ): Observable<ReinsuranceYear[]> {
    return this
    .get<ReinsuranceYear[]>( '/rma/reinsuranceyear', createHttpParams( params ) )
      .pipe(
        map(
          list => {
            if (list == null) {
              return [];
            }
            else {
              return list
                .map( x => new ReinsuranceYear( x. id, x.year, x.isActive ) )
                .sort(
                  (a, b) => {
                    if ( a.year > b.year ) return 1;
                    if ( b.year > a.year ) return -1;
                    return 0;
                  }
                );
            }
          }
        )
      );
  }
}

function createHttpParams(params: Record<string, string | undefined>): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      httpParams = httpParams.set(key, params[key] as string);
    }
  });

  return httpParams;
}
