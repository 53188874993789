import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import { Agency, Agent, Policy } from 'src/app/models/agency.model';
import { map, mergeMap } from 'rxjs/operators';
import { Address } from '../models/address.model';
import { RmaService } from './rma.service';

export interface AgentRequest {
  agentCode: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: Address;
}

export interface AgencySearchRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class AgencyAgentService extends BaseService {
  constructor(http: HttpClient, private rmaService: RmaService) {
    super(http);
  }

  createAgencyAgent(agent: Agent): Observable<Agent> {
    return this.post<Agency>('harvest-history-api/agency', agent.agency)
      .pipe(
        mergeMap((agency: Agency) => {
          return this.post<Agent>(`harvest-history-api/agency/${agency.id}/agent`, this.createAgentRequest(agent));
        })
      );
  }

  updateAgencyAgent(agent: Agent): Observable<Agent | null> {
    return this.put<Agency>(`harvest-history-api/agency/${agent.agency.id}`, agent.agency)
      .pipe(
        mergeMap((agency: Agency) => {
          if(agency == null) return of(null);
          return this.put<Agent>(`harvest-history-api/agent/${agent.id}`, this.createAgentRequest(agent));
        })
      );
  }

  searchAgents(request: AgencySearchRequest): Observable<Agent[]> {
    return this.getMany<Agent>(`harvest-history-api/agent`, new HttpParams({ fromObject: {...request} }));
  }

  getPolicies(harvestId: string, includeRecords: boolean = false): Observable<Policy[]> {
    if(includeRecords) {
      return forkJoin({
        policies: this.getMany<Policy>(`harvest-history-api/documentreview/harvest/${harvestId}/policies`),
        aips: this.rmaService.getAIPs()
      }).pipe(map(result => {
        result.policies.map(h => {
          h.aipRecord = result.aips.find(m => m.id === h.aip);
        });

        return result.policies;
      }));
    }
    else {
      return this.getMany<Policy>(`harvest-history-api/documentreview/harvest/${harvestId}/policies`);
    }
  }

  private createAgentRequest(agent: Agent): AgentRequest {
    return {
      agentCode: agent.agentCode,
      firstName: agent.firstName,
      lastName: agent.lastName,
      phoneNumber: agent.phoneNumber,
      email: agent.email,
      address: agent.address
    };
  }
}
