import { Component, Input, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Guid } from 'src/app/models/guid';
import { SBI } from '../../models/sbi.model';

@Component({
  selector: 'hh-sbis-list',
  templateUrl: './sbis-list.component.html',
  styleUrls: ['./sbis-list.component.scss']
})
export class SBIsListComponent {
  private _firstRowReadonly: any;
  @Input() existing: boolean = false;
  @Input()
  set firstRowReadonly(value: boolean) {
    this._firstRowReadonly = value;
    if (this.form?.length > 0) {
      if (value) {
        let group = this.form?.at(0);
        group.disable();
      } else {
        if (this.existing) {
          let group = this.form?.at(0);
          group.enable();
        } else {
          this.form.removeAt(0);
          if (this.form.length === 0) {
            let group = this.builder.group(this.rowConfig);
            group.valueChanges.subscribe(value => this.onGroupValueChanges(value, group));
            this.form.push(group);
          }
        }
      }

      this.table?.renderRows();
    }
  }
  @Input()
  set sbis(value: SBI[]) {
    this.updateForm(value);
  }

  @ViewChild(MatTable) table?: MatTable<FormGroup>;

  form: FormArray;
  rowConfig = {
    id: '',
    firstName: '',
    lastName: '',
    entityName: '',
    tinSuffix: ['', [Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/\d*/)]],
    isEntity: null
  };

  constructor(private builder: FormBuilder) {
    const group = this.builder.group(this.rowConfig);
    group.valueChanges.subscribe(value => this.onGroupValueChanges(value, group));
    this.form = this.builder.array([group]);
  }

  reset() {
    const group = this.builder.group(this.rowConfig);
    group.valueChanges.subscribe(value => this.onGroupValueChanges(value, group));
    this.form = this.builder.array([group]);
  }

  updateForm(sbis: SBI[]) {
    sbis.forEach((sbi: SBI, index: number) => {
      if (index >= this.form.length) {
        const group = this.builder.group(this.rowConfig);
        group.valueChanges.subscribe(value => this.onGroupValueChanges(value, group));
        this.form.push(group);
      }
      
      this.form.at(index)?.patchValue(sbi);
    });

    if (this._firstRowReadonly) {
      let group = this.form.at(0) as FormGroup;
      group?.disable();

      if (this.form.length > 1) {
        group = this.form.at(1) as FormGroup;
        group?.enable();
      }
    }

    this.table?.renderRows();
  }

  getFormGroup(index: number): FormGroup {
    return this.form.controls[index] as FormGroup;
  }

  doRowAction(index: number) {
    if (this.showAddButton(index)) {
      const group = this.builder.group(this.rowConfig);
      group.valueChanges.subscribe(value => this.onGroupValueChanges(value, group));
      this.form.push(group);
    } else if (this.showRemoveButton(index)) {
      this.form.removeAt(index);
    }

    this.table?.renderRows();
  }

  getActionIcon(index: number): string {
    if (this.showAddButton(index)) {
      return 'add_circle';
    } else if (this.showRemoveButton(index)) {
      return 'remove_circle';
    } else {
      return '';
    }
  }

  getActionText(index: number): string {
    if (this.showAddButton(index)) {
      return 'Add SBI';
    } else if (this.showRemoveButton(index)) {
      return 'Remove SBI';
    } else {
      return '';
    }
  }

  showButton(index: number): boolean {
    return this.showRemoveButton(index) || this.showAddButton(index);
  }

  markAsTouched() {
    this.form.markAllAsTouched();
  }

  get touched(): boolean {
    return this.form.touched && this.form.dirty;
  }

  get valid(): boolean {
    return this.form.valid || this.form.status == 'DISABLED';
  }
  
  get value(): SBI[] {
    let value = this.form.getRawValue().map(sbi => {
      sbi.id = Guid.EMPTY;
      sbi.isEntity = sbi.IsEntity ?? sbi.entityName != ''
      return sbi;
    });

    const validRows: SBI[] = [];
    value.forEach(sbi => {
      if (((sbi.firstName && sbi.lastName) || sbi.entityName) && !(sbi.firstName && sbi.lastName && sbi.entityName)) {
        validRows.push(sbi);
      }
    });

    return validRows;
  }

  private showRemoveButton(index: number): boolean {
    return !((this.form.controls.length === 1 || this._firstRowReadonly) && index === 0) && index !== this.form.controls.length - 1;
  }

  private showAddButton(index: number): boolean {
    return index === this.form.controls.length - 1;
  }

  private onGroupValueChanges(value: any, group: FormGroup) {
    const entityName = group.get('entityName');
    const firstName = group.get('firstName');
    const lastName = group.get('lastName');
    
    if ((value.firstName || value.lastName) && value.entityName) {
      entityName?.setErrors({'entered': true});
      entityName?.markAsTouched();

      if (value.firstName) {
        firstName?.setErrors({'entered': true});
        firstName?.markAsTouched();
      }

      if (value.lastName) {
        lastName?.setErrors({'entered': true});
        lastName?.markAsTouched();
      }
    }
    else if ((value.firstName && !value.lastName) || (!value.firstName && value.lastName)) {
      if (!firstName?.hasError('entered')) {
        firstName?.setErrors({'entered': true});
        firstName?.markAsTouched();
      }

      if (!lastName?.hasError('entered')) {
        lastName?.setErrors({'entered': true});
        lastName?.markAsTouched();
      }
    }
    else if (value.tinSuffix) {
      if (!value.firstName && !value.lastName && !value.entityName) {
        if (!firstName?.hasError('entered')) {
          firstName?.setErrors({'entered': true});
          firstName?.markAsTouched();
        }
        
        if (!lastName?.hasError('entered')) {
          lastName?.setErrors({'entered': true});
          lastName?.markAsTouched();
        }
        
        if (!entityName?.hasError('entered')) {
          entityName?.setErrors({'entered': true});
          entityName?.markAsTouched();
        }
      }
      else if ((value.firstName && !value.lastName) || (!value.firstName && value.lastName)) {
        if (!firstName?.hasError('entered')) {
          firstName?.setErrors({'entered': true});
          firstName?.markAsTouched();
        }
  
        if (!lastName?.hasError('entered')) {
          lastName?.setErrors({'entered': true});
          lastName?.markAsTouched();
        }

        if (entityName?.hasError('entered')) {
          let errors = {...entityName.errors};
          delete errors['entered'];
          entityName?.setErrors({...errors});
          entityName?.updateValueAndValidity({onlySelf: true});
        }
      }
      else if (!value.entityName) {
        if (value.firstName && value.lastName && entityName?.hasError('entered')) {
          let errors = {...entityName.errors};
          delete errors['entered'];
          entityName?.setErrors({...errors});
          entityName?.updateValueAndValidity({onlySelf: true});
        }

        if (firstName?.hasError('entered')) {
          let errors = {...firstName.errors};
          delete errors['entered'];
          firstName?.setErrors({...errors});
          firstName?.updateValueAndValidity({onlySelf: true});
        }
        
        if (lastName?.hasError('entered')) {
          let errors = {...lastName.errors};
          delete errors['entered'];
          lastName?.setErrors({...errors});
          lastName?.updateValueAndValidity({onlySelf: true});
        }
      }
      else {
        if (entityName?.hasError('entered')) {
          let errors = {...entityName.errors};
          delete errors['entered'];
          entityName?.setErrors({...errors});
        }
  
        if (firstName?.hasError('entered')) {
          let errors = {...firstName.errors};
          delete errors['entered'];
          firstName?.setErrors({...errors});
        }
        
        if (lastName?.hasError('entered')) {
          let errors = {...lastName.errors};
          delete errors['entered'];
          lastName?.setErrors({...errors});
        }

        entityName?.updateValueAndValidity({onlySelf: true});
        firstName?.updateValueAndValidity({onlySelf: true});
        lastName?.updateValueAndValidity({onlySelf: true});
      }
    }
    else {
      if (entityName?.hasError('entered')) {
        let errors = {...entityName.errors};
        delete errors['entered'];
        entityName?.setErrors({...errors});
      }

      if (firstName?.hasError('entered')) {
        let errors = {...firstName.errors};
        delete errors['entered'];
        firstName?.setErrors({...errors});
      }
      
      if (lastName?.hasError('entered')) {
        let errors = {...lastName.errors};
        delete errors['entered'];
        lastName?.setErrors({...errors});
      }

      entityName?.updateValueAndValidity({onlySelf: true});
      firstName?.updateValueAndValidity({onlySelf: true});
      lastName?.updateValueAndValidity({onlySelf: true});
    }

    group.updateValueAndValidity({onlySelf: true, emitEvent: false});
  }
}