import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Harvest } from 'src/app/models/harvest.model';
import { forkJoin, Observable, of } from 'rxjs';
import { SBI } from '../models/sbi.model';
import { ServiceType } from '../models/type.model';
import { UserService } from './user-service.service';
import { CropTypeSearchParameters, RmaService, StateSearchParameters } from './rma.service';
import { map, mergeMap } from 'rxjs/operators';
import { Review, ReviewResponse } from '../models/harvest-review.model';
import { AuthUser } from '../models/auth-user.model';
import { CropType, State } from '../models/rma.model';

@Injectable({
  providedIn: 'root'
})
export class HarvestService extends BaseService {

  constructor(http: HttpClient, private userService: UserService, private rmaService: RmaService) {
    super(http);
  }

  getHarvestsForReview(reviewId: string): Observable<Harvest[]> {
    return this.getMany<Harvest>(`harvest-history-api/documentreview/${reviewId}/harvest`);
  }

  getHarvestById(harvestId: string, includeRecords: boolean = false): Observable<Harvest> {
    if (includeRecords) {
      return this.get<Harvest>(`harvest-history-api/documentreview/harvest/${harvestId}`).pipe(
        mergeMap(harvest => this.rmaService.getCropTypeById(harvest.commodityId).pipe(
          mergeMap(commodity => this.rmaService.getStateById(harvest.state).pipe(
            map(state => {
              harvest.commodityRecord = commodity
              harvest.stateRecord = state
              return harvest;
            })
          ))
        ))
      )
    }
    else {
      return this.get<Harvest>(`harvest-history-api/documentreview/harvest/${harvestId}`);
    }
  }

  getHarvestsForUser(userId: string) {
    return this.getMany<Harvest>(`harvest-history-api/user/${userId}/harvest`);
  }

  getReviewHarvestsForUser(userId: string): Observable<Harvest[]> {
    return this.getMany<Harvest>(`harvest-history-api/documentreview/user/${userId}/harvest`);
  }

  getReviewByHarvestId(harvestId: string) {
    return this.get<ReviewResponse>(`harvest-history-api/documentreview/review/harvest/${harvestId}`)
  }

  getReviewDocumentYear(harvestId: string): Observable<string> {
    return forkJoin({
      review: this.getReviewByHarvestId(harvestId),
    }).pipe(map(result => {
      if(result.review && result.review.documents && result.review.documents.length > 0) {
        if(result.review.documents[0].documentType.type == "ProductionReport") {
          return (parseInt(result.review.documents[0].plantedYear) + 1).toString()
        }
        else {
          return result.review.documents[0].plantedYear
        }
      }
      else {
        return new Date().getFullYear().toString()
      }
    }));
  }

  updateSBIs(harvestId: string, sbis: SBI[]): Observable<SBI[]> {
    return this.post<SBI[]>(`harvest-history-api/documentreview/harvest/${harvestId}/sbi`, sbis);
  }

  updateReviewToLocked(reviewId: string): Observable<Review | null> {
    return forkJoin({
      statuses: this.getReviewStatuses(),
      currentUser: this.userService.getProfile()
    })
    .pipe(
      mergeMap(result => {
        if (!result.statuses || result.statuses.length == 0 || !result.currentUser) {
          return of(null);
        }

        let locked = result.statuses.find(r => r.description.toLocaleLowerCase() == "locked");

        return this.put<Review>(`harvest-history-api/documentreview/${reviewId}`,
        {
          statusId: locked?.id ?? '',
          reviewerName: result.currentUser.displayName,
          reviewerId: result.currentUser.id
        });
      })
    );
  }

  updateReviewToComplete(reviewId: string): Observable<Review> {
    return this.post<Review>(`harvest-history-api/documentreview/${reviewId}/complete`, {});
  }

  updateReviewToAbandoned(reviewId: string): Observable<Review> {
    return this.post<Review>(`harvest-history-api/documentreview/${reviewId}/abandon`, {});
  }

  getAllHarvestsForReview(): Observable<Review[]> {
    return this.getReviewStatuses()
    .pipe(
      mergeMap(statuses => {
        if (!statuses || statuses.length == 0) {
          return [];
        }

        const stats = statuses.filter(x => x.type.toLocaleLowerCase() === 'open' || x.type.toLocaleLowerCase() === 'locked');
        let query: HttpParams = new HttpParams();
        stats.forEach(status => query = query.append('statusid', status.id));
        return this.getMany<Review>('harvest-history-api/documentreview', query)
        .pipe(
          mergeMap(reviews  => {
            if(!reviews || reviews.length == 0) {
              return [];
            }

            var userEmails = reviews.map(review => review.email)
            return forkJoin({
              reviews: of(reviews),
              users: this.userService.getUserInfo(userEmails)
            });
          }),
          map((results: { reviews: Review[], users: AuthUser[] }) => {
            let reviews = results.reviews.map(review => {
              const userRecord = results.users.find(u => u.email === review.email);
              let user: AuthUser = {
                id: userRecord?.id ?? '',
                fullName: userRecord?.fullName ?? '',
                firstName: userRecord?.firstName ?? '',
                lastName: userRecord?.lastName ?? '',
                cellPhone: userRecord?.cellPhone ?? '',
                email: userRecord?.email ?? 'not found',
                displayName: userRecord?.displayName ?? '',
                issuerAssignedId: userRecord?.issuerAssignedId ?? '',
                userPrincipalName: userRecord?.userPrincipalName ?? '',
                businessPhone: userRecord?.businessPhone ?? '',
                lastNameFirst: userRecord?.lastNameFirst ?? ''
              };

              review.firstName = user.firstName;
              review.lastName = user.lastName;
              review.email = user.email;
              review.userId = user.id;
              review.status = review.reviewerName != "" ? review.reviewerName : "Ready For Intake";
              review.state = review.harvest.state;
              review.canStart = review.status != review.reviewerName;
              review.canComplete = review.status == review.reviewerName;
              review.canContinue = review.status == review.reviewerName;
              review.commodityId = review.harvest.commodityId;
              review.commodityName = review.harvest.commodityName;
              return review;
            });
            return reviews.sort((a, b) => a.firstName > b.firstName ? -1: 1);
          })
        );
      })
    );
  }

  getReviewsByStatus(statusIds: string[]): Observable<Review[]> {
    let query: HttpParams = new HttpParams();
    statusIds.forEach(element => {
      query = query.append('statusId', element);
    })

    return this.getMany<Review>('harvest-history-api/documentreview', query)
      .pipe(
        mergeMap((reviews: Review[]) => {
          if (!reviews || reviews.length == 0) {
            return [];
          }

          let reviewers = reviews.map(rev => rev.email);
          let uniqueValues = reviewers.filter((n, i) => reviewers.indexOf(n) === i).filter(x => x!= null);
          let crops = this.rmaService.getCropTypes();
          let states = this.rmaService.getStates();

          return forkJoin({reviews: of(reviews), crops: crops, states: states, reviewers:this.post<AuthUser[]>('/user-information/information/emails', {ids: uniqueValues})});
        }),
        map((results: {reviews: Review[], crops: CropType[], states: State[], reviewers: AuthUser[]}) => {
          let reviews = results.reviews.map(review => {
            const userRecord = results.reviewers.find(u => u.email === review.email);
            const cropType = review.reviewerName === "Abandoned" ? undefined : results.crops.find(c => c.id == review.harvest.commodityId);
            const state = review.reviewerName === "Abandoned" ? undefined:  results.states.find(s =>  s.id == review.harvest.state);

            let user: AuthUser = {
              id: userRecord?.id ?? '',
              fullName: userRecord?.fullName ?? '',
              firstName: userRecord?.firstName ?? '',
              lastName: userRecord?.lastName ?? '',
              cellPhone: userRecord?.cellPhone ?? '',
              email: userRecord?.email ?? 'not found',
              displayName: userRecord?.displayName ?? '',
              issuerAssignedId: userRecord?.issuerAssignedId ?? '',
              userPrincipalName: userRecord?.userPrincipalName ?? '',
              businessPhone: userRecord?.businessPhone ?? '',
              lastNameFirst: userRecord?.lastNameFirst ?? ''
            };

            review.firstName = user.firstName;
            review.lastName = user.lastName;
            review.email = user.email;
            review.userId = user.id;
            review.status = review.reviewerName != "" ? review.reviewerName : "Ready For Intake";
            review.stateName = state?.display ?? '';
            review.canStart = review.status != review.reviewerName;
            review.canComplete = review.status == review.reviewerName;
            review.canContinue = review.status == review.reviewerName;
            review.canAbandon = review.reviewerName !== "Abandoned";
            review.commodityId = review.harvest?.commodityId;
            review.commodityName = cropType?.display ?? '';
            return review;
          });
          return reviews.sort((a, b) => a.firstName > b.firstName ? -1: 1);
        })
      );
  }

  getReviewStatuses(): Observable<ServiceType[]> {
    return this.getMany<ServiceType>(`harvest-history-api/documentreview/status`);
  }
}
