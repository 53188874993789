import { FormGroup } from "@angular/forms";
import { HttpResponseMessageMap } from "./messages/http-response-messages";
import { MessageContent } from "./messages/messages.component";

export function commasSeparatedWithConjunction(itemArray: string[], conjunction: string = 'and'): string {
  let itemString = '';
  if (itemArray.length === 1) {
    itemString = itemArray[0];
  } else {
    itemArray.forEach((item, index) => {
      if (index === itemArray.length - 1) {
        itemString += conjunction + ' ' + item;
      } else {
        itemString += item;
        if (index !== itemArray.length - 2) {
          itemString += ',';
        }
        itemString += ' ';
      }
    });
  }

  return itemString;
}

export function fieldHasError(form: FormGroup, controlName: string, error: string): boolean {
  return form?.get(controlName)?.hasError(error) ?? false;
}

export function flattenObjectArray<TParent, TChild>(parentArray: TParent[], propertyName: string): TChild[] {
  return parentArray.reduce((arr: TChild[], item: TParent) => arr = arr.concat((item as any)[propertyName]), []);
}

export function createMessageContent(httpStatusCode: number): MessageContent | null {
  if (!HttpResponseMessageMap[httpStatusCode]) return null;

  return {
    title: `${httpStatusCode} ${HttpResponseMessageMap[httpStatusCode].response.statusText}`,
    messages: [HttpResponseMessageMap[httpStatusCode].messageText]
  }
}

export function validEmail(value: string): boolean {
  if(value && value !== '') {
    let pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if(value.toLowerCase().match(pattern)) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}

export function getCurrentReinsuranceYear(): number {
  let dt: Date = new Date();
  dt.setMonth(dt.getMonth()+6);
  return dt.getFullYear();
}