import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTabGroup, MatTabHeader, MatTab } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { MessageContent } from 'src/app/messages/messages.component';
import { AuthUser } from 'src/app/models/auth-user.model';
import { IHasChanges } from 'src/app/models/component.model';

import { EventBroadcastService } from 'src/app/services/event-broadcast.service';
import { UserService } from 'src/app/services/user-service.service';
import { createMessageContent } from 'src/app/utility';

@Component({
  selector: 'hh-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  user?: AuthUser;
  currentHarvestRouterComponent!: IHasChanges;

  messageTitle: string = '';
  messages: string[] = [];

  private messageSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dialogService: DialogService,
    private broadcastService: EventBroadcastService) { }

  ngOnInit(): void {
    this.subscribeToParams();
  }

  ngAfterViewInit(): void {
    this.tabGroup._handleClick = this.onTabClick.bind(this);
    this.messageSubscription = this.broadcastService.addEventListener('message').subscribe(event => this.handleErrorMessage(event.payload));
  }

  ngOnDestroy(): void {
    this.messageSubscription?.unsubscribe();
  }

  subscribeToParams() {
    this.route.params.subscribe(params => {
      this.userService.getUserByEmail(params['id'])
        .subscribe(value => {
          this.user = value;
        },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.messageTitle = message?.title ?? '';
            this.messages = message?.messages ?? [];
          }
          else {
            this.messageTitle = 'Error';
            this.messages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
          }
        });
    });
  }

  onHarvestOutletActivate(component: IHasChanges): void {
    this.currentHarvestRouterComponent = component;
  }

  private onTabClick(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    if (tab.textLabel !== 'Harvest Details' && this.currentHarvestRouterComponent && this.currentHarvestRouterComponent.hasChanges()) {
      this.dialogService.openUnsavedChangesDialog()
        .subscribe(doLeave => {
          if (doLeave) {
            this.currentHarvestRouterComponent.markAsClean();
            MatTabGroup.prototype._handleClick.apply(this.tabGroup, [tab, tabHeader, idx]);
          }
        });
    }
    else MatTabGroup.prototype._handleClick.apply(this.tabGroup, [tab, tabHeader, idx]);

    return false;
  }

  private handleErrorMessage(msgContent: MessageContent) {
    if(msgContent) {
      this.messageTitle = msgContent.title;
      this.messages = msgContent.messages;
    }
  }
}
