import { Component, Input, OnInit } from '@angular/core';

export interface MessageContent {
  title: string;
  messages: string[];
}

export type MessageType = 'alert' | 'info' | 'success' | 'warning' | '';

@Component({
  selector: 'hh-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input() messageType: MessageType = 'success';
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() messages: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  get messageTitle(): string {
    if (this.title) {
      return this.title;
    }
    switch (this.messageType) {
      case 'alert':
        return 'Error on Page';
      case 'warning':
        return 'Warning Message';
      case 'success':
      case 'info':
      default:
        return '';
    }
  }

  get messageIcon(): string {
    if (this.icon.length > 0) {
      return this.icon;
    }

    switch (this.messageType) {
      case 'alert':
        // return 'cancel'
        return 'fas fa-times-circle';
      case 'info':
        // return 'info';
        return 'fas fa-info-circle';
      case 'success':
        // return 'check_circle_outline';
        return 'fas fa-check-circle';
      case 'warning':
        // return 'error_outline';
        return 'fas fa-exclamation-circle'
      default:
        return '';
    }
  }
}
