import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageType } from 'src/app/messages/messages.component';
import { Address } from 'src/app/models/address.model';
import { Guid } from 'src/app/models/guid';
import { HarvestOwner, OwnerType } from 'src/app/models/harvest-owner.model';
import { DocumentService } from 'src/app/services/document.service';
import { HarvestOwnerSearchCriteria, HarvestOwnerService } from 'src/app/services/harvest-owner.service';
import { getHarvestOwnerName } from 'src/app/models/utility';

@Component({
  selector: 'hh-harvest-owner-search',
  templateUrl: './harvest-owner-search.component.html',
  styleUrls: ['./harvest-owner-search.component.scss']
})
export class HarvestOwnerSearchComponent implements OnInit {
  @Input()
  set documentId(value: string) {
    if (value !== null && value.length > 0) {
      this.documentService.getDocumentFile(value)
        .subscribe(result => this.url = result);
    }
  }

  @Output() selectedHarvestOwner = new EventEmitter<HarvestOwner>();
  @Output() harvestOwnerType = new EventEmitter<OwnerType>();

  displayColumns = ['select', 'name', 'doingBusinessAs', 'emailAddress', 'phoneNumber', 'tin']

  ownerType: OwnerType = OwnerType.Individual;
  ownerTypes = OwnerType;

  noOwnersFound: boolean = false;
  searched: boolean = false;
  criteriaSearched: string = '';

  selection = new SelectionModel<HarvestOwner>(false);
  harvestOwners: HarvestOwner[] = [];

  form: FormGroup;
  url: string = '';

  emptyOwner: HarvestOwner = {
    id: Guid.EMPTY,
    firstName: '',
    lastName: '',
    entityName: '',
    doingBusinessAs: '',
    phoneNumber: '',
    emailAddress: '',
    isEntity:false,
    tinSuffix: ''
  }

  constructor(private builder: FormBuilder, private documentService: DocumentService, private harvestOwnerService: HarvestOwnerService) {
    this.form = this.builder.group({
      firstName: '',
      lastName: '',
      entityName: ''
    });

    this.form.valueChanges.subscribe(value => {
      this.emptyOwner = {
        ...this.emptyOwner,
        firstName: value.firstName,
        lastName: value.lastName,
        entityName: value.entityName,
        isEntity: this.ownerType === OwnerType.Entity
      };
    });

    this.selection.changed.subscribe(value => {
      this.selectedHarvestOwner.emit(value.added[0]);
    })
  }

  ngOnInit(): void {
  }

  changeOwnerType(ownerType: OwnerType) {
    this.ownerType = ownerType;
    this.searched = false;
    this.selection.clear();
    if (ownerType === OwnerType.Individual) {
      this.form.get('entityName')?.setValue('');
    } else if (ownerType === OwnerType.Entity) {
      this.form.get('firstName')?.setValue('');
      this.form.get('lastName')?.setValue('');
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.harvestOwnerType.emit(ownerType);
  }

  ownerTypeIs(ownerType: OwnerType): boolean {
    return this.ownerType === ownerType;
  }

  search() {
    this.harvestOwners = [];
    this.selection.clear();
    this.searched = false;

    let criteria: HarvestOwnerSearchCriteria = {
      firstName: this.ownerTypeIs(OwnerType.Individual) ? this.form.get('firstName')?.value : '',
      lastName: this.ownerTypeIs(OwnerType.Individual) ? this.form.get('lastName')?.value : '',
      entityName: this.ownerTypeIs(OwnerType.Entity) ? this.form.get('entityName')?.value : '',
      isEntity: this.ownerTypeIs(OwnerType.Entity)
    };

    this.harvestOwnerService.getHarvestOwners(criteria)
      .subscribe(result => {
        this.searched = true;
        this.harvestOwners = result ?? [];
        this.noOwnersFound = this.harvestOwners.length === 0;

        this.criteriaSearched = '';
        if (this.ownerTypeIs(OwnerType.Individual)) {
          if (criteria.firstName) {
            this.criteriaSearched += criteria.firstName;
          }

          if (criteria.lastName) {
            if (this.criteriaSearched) {
              this.criteriaSearched += ' ';
            }

            this.criteriaSearched += criteria.lastName;
          }
        } else if (this.ownerTypeIs(OwnerType.Entity)) {
          this.criteriaSearched = criteria.entityName;
        }

        if (this.noOwnersFound) {
          this.selectedHarvestOwner.emit(this.emptyOwner);
        }
      });
  }

  getName = getHarvestOwnerName;

  get title(): string {
    if (!this.searched) {
      return '';
    }
    if (this.noOwnersFound) {
      return 'No existing Harvest Owner found';
    }
    return 'Existing Harvest Owner Found';
  }

  get messageType(): MessageType {
    return this.searched ? (this.noOwnersFound ? 'alert' : 'success') : '';
  }

  get messages(): string[] {
    if (!this.searched) {
      return [];
    }
    if (this.noOwnersFound) {
      return [`No Harvest Owner is associated to "${this.criteriaSearched}". Please provide more information found on the <a href="${this.url}" target="_blank"><strong>document</strong></a>.`];
    }

    return [`Existing Harvest Owner found associated to "${this.criteriaSearched}". Please select the appropriate Harvest Owner or enter the details found on the <a href="${this.url}" target="_blank"><strong>document</strong></a>.`]
  }
}
